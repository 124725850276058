import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useHistory, withRouter } from 'react-router-dom';

const LeftMenu = (props) => {
  let history = useHistory();

  const switchShipper = (shipper) => {
    // const shipperList = props.shippers;
    // const isValidShipper = shipperList.find((item) => item.id === shipper?.id);
    // if (isValidShipper) {
    props.dispatch({ type: 'SHIPPER', payload: shipper });
    if (shipper.role === 'viewer') {
      toast.error(`You don't have access to create a shipment for ${shipper.name}. Please contact admin`);
      history.push('/shipments');
    }
    // }
  };

  const { tenant_logo, user, location } = props;
  const profileUrl = user.photo_url;

  return (
    <>
      <nav className='navbar navbar-vertical fixed-start navbar-expand-md navbar-light' id='sidebar'>
        <div className='container-fluid'>
          <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#sidebarCollapse' aria-controls='sidebarCollapse' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>

          <Link to='/dashboard' className='navbar-brand'>
            <div className='text-center'>
              <img src={tenant_logo} alt='logo' className='img-fluid' />
            </div>
          </Link>

          <div className='collapse navbar-collapse' id='sidebarCollapse'>
            {/* <div className='dropdown'>
              <button className='btn btn-sm btn-light dropdown-toggle w-100 text-wrap' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                {props.shipper.name}
              </button>
              <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                <button
                  className='dropdown-item'
                  onClick={() =>
                    switchShipper({
                      alias: "All Shipments",
                      id: "",
                      name: "All Shipments",
                      role: "All Shipments",
                    }
                    )
                  }>
                  All Shipments
                </button>
                {props?.shippers?.map((shipper) => {
                  return (

                    <button
                      key={`menu_${shipper.id}`}
                      className='dropdown-item'
                      onClick={() => {
                        switchShipper(shipper);
                      }}>
                      {shipper?.alias || shipper?.name}
                    </button>
                  );
                })}
              </div>
            </div> */}

            <hr className='navbar-divider my-3' />

            <ul className='navbar-nav mt-2'>
              <li className='nav-item'>
                <Link to='/dashboard' className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                  <i className='fe fe-home'></i>Dashboard
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/live-tracker' className='nav-link' target={'_blank'}>
                  <i className='fe fe-map'></i>Live Tracking
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/shipments' className={`nav-link ${location.pathname.includes('/shipment') ? 'active' : ''}`}>
                  <i className='fe fe-package'></i>Shipments
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/feedback' className={`nav-link ${location.pathname === '/feedback' ? 'active' : ''}`}>
                  <i className='fe fe-star'></i>Feedback
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/users' className={`nav-link ${location.pathname === '/users' ? 'active' : ''}`}>
                  <i className='fe fe-users'></i> Users
                </Link>
              </li>
            </ul>

            <hr className='navbar-divider my-3' />

            <ul className='navbar-nav'>
              <li className='nav-item'>
                <Link to='/profile' className={`nav-link ${location.pathname === '/profile' ? 'active' : ''}`}>
                  <i className='fe fe-user'></i> My Profile
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/logout' className={`nav-link ${location.pathname === '/logout' ? 'active' : ''}`}>
                  <i className='fe fe-log-out'></i> Logout
                </Link>
              </li>
            </ul>

            <div className='navbar-user d-none'>
              <div className='dropup'>
                <a href='#!' className='avatar avatar-sm dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                  <img src={profileUrl ? profileUrl : '/img/default-user.png'} alt='...' className='avatar-img rounded-circle' />
                </a>
                <div className='dropdown-menu dropdown-menu-right'>
                  {props.shippers.length > 0 && (
                    <>
                      {props?.shippers?.map((shipper, index) => {
                        return (
                          <button
                            key={index}
                            className='dropdown-item'
                            onClick={() => {
                              switchShipper(shipper);
                            }}>
                            {shipper.id === props.shipper.id ? <strong className='text-primary'>{shipper.name}</strong> : <div>{shipper.name}</div>}
                          </button>
                        );
                      })}
                      <hr className='dropdown-divider' />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant_logo: state.tenant_logo,
    user: state.user,
    shippers: state.shippers,
    shipper: state.shipper,
  };
};

export default connect(mapStateToProps)(withRouter(LeftMenu));
