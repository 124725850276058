import React from 'react';

const Avatar = ({ size, alt, image }) => {
  return (
    <div className={`avatar avatar-${size || 'sm'}`}>
      <img className='avatar-img rounded-circle' alt={alt || 'avatar'} src={image || '/img/default-user.png'} />
    </div>
  );
};

export default Avatar;
