import React from 'react';
import Select from 'react-select';

const SelectField = (props) => {
  const handleChange = (value) => {
    const { onChange, name } = props;
    onChange(name, value);
  };

  const { id, name, label, placeholder, options, value, isMulti, isDisabled, error, isClearable, backspaceRemovesValue } = props;
  return (
    <div className='input-field-wrapper'>
      {label && (
        <h3 className='input-label' htmlFor={name} error={error}>
          {label}
        </h3>
      )}
      <Select id={id} placeholder={placeholder} options={options} value={value} onChange={handleChange} error={error} isMulti={isMulti} isDisabled={isDisabled} isClearable={isClearable} backspaceRemovesValue={backspaceRemovesValue} components={{ ClearIndicator: null }}  theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
        ...theme.colors,
        },
      })}/>
      {error ? <p className='error-text small text-danger'>{error}</p> : null}
    </div>
  );
};

export { SelectField };
