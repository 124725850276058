import React from 'react';

export const StatusBadge = ({ status, size }) => {
  return (
    <div className={size || 'h3 mb-0'}>
      {status === 'Info Received' && <span className='badge bg-primary-soft'>{status}</span>}
      {status === 'Processing' && <span className='badge bg-primary-soft'>{status}</span>}
      {status === 'Ready for Pickup' && <span className='badge bg-primary-soft'>{status}</span>}

      {status === 'Out for Delivery' && <span className='badge bg-warning-soft'>{status}</span>}
      {status === 'Out for Return' && <span className='badge bg-warning-soft'>{status}</span>}

      {status === 'Delivered' && <span className='badge bg-success-soft'>{status}</span>}
      {status === 'Returned' && <span className='badge badge-bg-purple'>{status}</span>}

      {status === 'Exception' && <span className='badge bg-danger-soft'>Cancelled</span>}
    </div>
  );
};

export const StatusHeader = ({ status, size }) => {
  return (
    <div className={size || 'h1 mb-0'}>
      {status === 'Info Received' && <span className='text-primary'>{status}</span>}
      {status === 'Processing' && <span className='text-primary'>{status}</span>}
      {status === 'Ready for Pickup' && <span className='text-primary'>{status}</span>}

      {status === 'Out for Delivery' && <span className='text-warning'>{status}</span>}
      {status === 'Out for Return' && <span className='text-warning'>{status}</span>}

      {status === 'Delivered' && <span className='text-success'>{status}</span>}
      {status === 'Returned' && <span className='text-purple'>{status}</span>}

      {status === 'Exception' && <span className='text-danger'>Cancelled</span>}
    </div>
  );
};
