import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CropModal = ({ defaultName, src, crop, imageUrl, name, imageLoading, onImageLoad, onImageUpload, onCropComplete, onCropChange, getCropImg }) => {
  return (
    <>
      <div class='modal-dialog modal-dialog-centered' role='document'>
        <div class='modal-content'>
          <div class='modal-header'>
            <h3 className='modal-title' id='pharmacyTitle'>
              <span className='text-small text-muted'>{`${name ? name : defaultName}'s Logo`}</span>
            </h3>
            <button type='button' className='close btn btn-white' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>Close</span>
            </button>
          </div>
          <div class='modal-body '>
            {' '}
            <div className={src ? 'embed-responsive embed-responsive d-flex justify-content-center' : 'embed-responsive embed-responsive-2by1 d-flex justify-content-center'}>{<ReactCrop crossorigin='anonymous' src={src ? src : imageUrl ? imageUrl : '/img/pharmacy-default.png'} crop={crop} ruleOfThirds onImageLoaded={onImageLoad} onComplete={onCropComplete} onChange={onCropChange} />}</div>
          </div>
          <div class='modal-footer  d-flex justify-content-end'>
            <label className='btn btn-secondary' htmlFor='browse-file-controller'>
              <i className='fe fe-image'></i> Choose File
            </label>
            <input
              type='file'
              name='file'
              accept='image/*'
              id='browse-file-controller'
              className='inputfile d-none'
              onChange={(e) => {
                onImageUpload(e);
              }}
            />
            <span id='imagetypeerror' className='error-text' />
            {imageLoading === true ? (
              <div className='spinner-border mb-3' role='status' />
            ) : (
              <button
                type='save'
                className='btn btn-primary'
                disabled={!src}
                data-dismiss='modal'
                onClick={(e) => {
                  getCropImg(e);
                }}>
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CropModal;
