import React from 'react'

const ListGroupItem = ({ label, value, key }) => {
  return (
    <li className='list-group-item'>
      <div className='row'>
        <div className='col'>{label}</div>
        <div className='col-auto text-muted'>{value}</div>
      </div>
    </li>
  );
};

export default ListGroupItem