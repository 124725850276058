
import React,  { useState } from 'react'
import { Link } from 'react-router-dom'
import convert from 'xml-js';
import { toast } from 'react-toastify';
import axios from 'axios'
import Spinner from '../../components/spinner';


const SelectShipmentMethod = (props) => {

    const [loading, showSpinner] = useState(false);

    const getAccountStatus = async () => {
        showSpinner(true)
         await axios('/endicia/account_status').then((res) => {
            let xmlData = convert.xml2json(res.data, { compact: true, spaces: 4 });
            let jsonStr = (JSON.parse(xmlData)['soap:Envelope']['soap:Body'].GetAccountStatusResponse.AccountStatusResponse.CertifiedIntermediary.AccountStatus._text);
            if (jsonStr.toLowerCase() === 'a') {
                showSpinner(false)
                props.history.push('/shipment/create-usps-shipment');
            } else {
                toast.error('Oops! USPS Account is not active, Please try again or contact the admin.');
            }
        })
    }
    return (
        <Spinner display={loading}>
        <div className="d-flex  justify-content-center " >
            <div className="card   " style={{ minHeight: '50vh', width: '100vw' }}>
                <div className="card-header mt-5">
                    <h1>
                        Select Courier Method
                    </h1>
                </div>
                <div className="card-body text-center mt-5">
                    <div className="Shipment-Method-options mb-5">
                        <Link to='/shipment/create'>
                            <div className="form-check form-check-inline  Method-options-icon  hover-underline-animation phox">
                                <img src="../img/logos/phoxhealth-logo.png" className='shipment_Method_Icon phox-icon mt-3' alt="phoxHealth" />
                            </div>
                        </Link>
                        <div className="form-check form-check-inline Method-options-icon  hover-underline-animation usps " onClick={getAccountStatus}>
                            <img src="../img/logos/USPS-logo.png" className='shipment_Method_Icon usps-icon mt-3 ' alt="USPS"  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Spinner>
    )
}

export default SelectShipmentMethod;