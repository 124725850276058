/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import moment from 'moment';

import { getData } from '../../services/index';
import Spinner from '../../components/spinner';
import Header from '../../components/pageHeader';
import NPSBadge from '../../components/npsBadge';
import { toUtcDate, toLocalDate, updateToken } from '../../helpers/utils';

const FeedbackList = (props) => {
  const take = 100;

  const [skip, setSkip] = useState(0);
  const [spinner, showSpinner] = useState(false);
  const [feedbackList, setFeedback] = useState([]);
  const [dateFilters, setDateFilters] = useState({
    fromDate: `${moment().subtract(6, 'days').format('YYYY-MM-DD')}T00:00:00`,
    toDate: `${moment().format('YYYY-MM-DD')}T23:59:59`,
  });

  useEffect(() => {
    getFeedback();
  }, [props.shipper, skip, dateFilters.fromDate, dateFilters.toDate]);

  const getFeedback = async () => {
    showSpinner(true);
    const url = `shipment/feedback?shipper=${props.shipper.id}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}`;
    const response = await getData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setFeedback(response?.data);
        showSpinner(false);
      } else {
        if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
          await updateToken();
        }
        if (response?.response?.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
    }
  };

  const pagePrevious = () => {
    setSkip(skip - take);
  };

  const pageNext = () => {
    setSkip(skip + take);
  };

  const handleDateChange = (event, picker) => {
    const fromDate = `${moment(picker.startDate).format('YYYY-MM-DD')}T00:00:00`;
    const toDate = `${moment(picker.endDate).format('YYYY-MM-DD')}T23:59:59`;
    setDateFilters({ fromDate, toDate });
  };

  return (
    <>
      <Header title={props.shipper.name} name='Delivery Feedback' />
      <div className='container-fluid'>
        <div className='card'>
          <div className='card-header'>
            <div className='row'>
              <div className='col'>
                <div className='input-group input-group-sm input-group-flush input-group-merge input-group-reverse'>
                  <input className='form-control list-search' type='text' placeholder='Search' value={''} />
                  <span className='input-group-text'>
                    <i className='fe fe-search'></i>
                  </span>
                </div>
              </div>
              <div className='col-auto'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment().subtract(6, 'days'),
                    endDate: moment(),
                    linkedCalendars: true,
                    showCustomRangeLabel: true,
                    showDropdowns: true,
                    alwaysShowCalendars: true,
                    opens: 'left',
                    ranges: {
                      'Today': [moment(), moment()],
                      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                      'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    },
                  }}
                  onApply={handleDateChange}>
                  <input className='btn btn-sm btn-light' style={{ cursor: 'pointer' }} />
                </DateRangePicker>
              </div>
            </div>
          </div>
          <Spinner display={spinner}>
            <table className='table table-hover table-sm mb-0'>
              <thead>
                <tr>
                  <th className='text-center'>Shipment #</th>
                  <th>Patient</th>
                  <th className='text-center'>Delivery Rating</th>
                  <th className='text-center'>NPS Score</th>
                  <th className='text-center'>Date</th>
                </tr>
              </thead>
              {feedbackList?.length > 0 &&
                feedbackList?.map((feedback, index) => {
                  return (
                    <tbody key={index}>
                      <tr style={{ cursor: 'pointer' }} onClick={() => window.open(`shipment/${feedback.shipment_id}/overview`, '_blank')}>
                        <td className='text-center'>{feedback.number}</td>
                        <td>
                          {feedback.comment}
                          <div className='text-muted h5 mt-1'>{feedback.user_name}</div>
                        </td>
                        <td className='text-center'>
                          <StarRatings rating={feedback.rating} starRatedColor='rgb(246,195,67)' starDimension='16px' starSpacing='1px' numberOfStars={5} name='rating' />
                        </td>
                        <td className='text-center'>{<NPSBadge nps={feedback.nps} />}</td>
                        <td className='text-center'>{toLocalDate(feedback.create_date, 'lll')}</td>
                      </tr>
                    </tbody>
                  );
                })}
              <tfoot>
                <tr>
                  <td colSpan={6}>
                    <ul className='pagination pagination-sm justify-content-center mb-0'>
                      <li className={`page-item ${skip <= 0 ? 'disabled' : ''}`}>
                        <button
                          className='page-link'
                          onClick={() => {
                            pagePrevious();
                          }}>
                          Previous
                        </button>
                      </li>
                      <li className='page-item disabled'>
                        <button className='page-link'>Page {skip / take + 1}</button>
                      </li>
                      <li className={`page-item ${feedbackList.length < take ? 'disabled' : ''}`}>
                        <button
                          className='page-link'
                          onClick={() => {
                            pageNext();
                          }}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Spinner>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
  };
};

export default connect(mapStateToProps)(FeedbackList);
