import React, { useEffect } from 'react';
import { trackPageView } from '../helpers/analytics';

const PageHeader = ({ title, name, children }) => {

    useEffect(() => {
        document.title = `${name} - Phox Health`;
        trackPageView();
    }, [name])

    return (
        <div className='header'>
            <div className='container-fluid'>
                <div className='header-body'>
                    <div className='row align-items-end'>
                        <div className='col'>
                            <h6 className='header-pretitle'>{title}</h6>
                            <h1 className='header-title'>{name}</h1>
                        </div>
                        <div className='col-auto'>
                            {
                                children && <>{children}</>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageHeader;