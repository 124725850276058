import moment from 'moment';
import axios from 'axios';
import { refreshToken } from '../services/index';

export const sortData = (data) => {
  const sortedData = [...data];
  return sortedData.sort((a, b) => (a.cases > b.cases ? -1 : 1));
};

export const isValidEmail = (email) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = pattern.test(email);
  return result;
};

export const isValidPhone = (phone) => {
  const pattern = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
  const result = pattern.test(phone);
  return result;
};

export const checkExtension = (file) => {
  const isValidExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
  if (['.jpg', '.jpeg', '.png', '.gif', '.jfif'].some((e) => e === String(isValidExtension))) return false;
  return true;
};

export const toUtcDate = (date) => {
  if (!date) return '';
  return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
};

export const toLocalDate = (utcDate, format) => {
  if (!utcDate) return '';
  return moment.utc(utcDate).local().format(format);
};

export const getDateTimeDiffernce = (futureDateTime, pastDateTime) => {
  if (!futureDateTime || !pastDateTime) {
    return '';
  }
  const differenceInMinutes = moment(futureDateTime).diff(moment(pastDateTime), 'minute');
  const hours = Math.floor(differenceInMinutes / 60);
  const minutes = differenceInMinutes % 60;
  const difference = hours > 0 && minutes <= 0 ? `${hours} hr` : hours <= 0 && minutes > 0 ? `${minutes} mins` : `${hours} hr, ${minutes} mins`;
  return difference;
};

//Turn Around Time
export const getTAT = (deliveryDateTime, createdDateTime) => {
  if (!deliveryDateTime || !createdDateTime) {
    return '';
  }
  return `${moment(deliveryDateTime).diff(moment(createdDateTime), 'minute')}`;
};

export const updateToken = async () => {
  const response = await refreshToken();
  if (response && response.status === 200 && response?.data) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data?.token}`;
    await localStorage.setItem('ph-shipper-token', response.data?.token);
    window.location.reload();
  } else {
    window.location.href = '/';
  }
  return response;
};

export const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const getEstimatedTimeslot = (duration) => {
  const totalSeconds = parseInt(duration, 10);
  let hours = Math.floor(totalSeconds / 3600); // get hours
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60); // get minutes
  let seconds = totalSeconds - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10;
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  const time = hours > 0 ? `ETA ${Math.round(hours)} hr ${minutes} min` : `ETA ${Math.round(minutes)} min ${seconds} sec`;
  return time;
};
export const mapStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
};