const fedexboxData = [
    {
        id: "0",
        type: 'Select one',
    },
    {
        id: "1",
        type: 'Small Mailer (7.5 x 10.5 x 0)',
    },
    {
        id: "2",
        type: 'Medium Mailer (9 x 12 x 0)',
    },
    {
        id: "3",
        type: 'Large Mailer (12 x 15.5 x 0)',
    },
    {
        id: "4",
        type: 'Small Box (11 x 11 x 7)',
    },
    {
        id: "5",
        type: 'Medium (11.25 x 8.75 x 6)',
    },
    {
        id: "6",
        type: 'Large/Vial Box (12 x 12.5 x 13.5)',
    },


]

export default fedexboxData;