import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

import * as Yup from 'yup';
import swal from 'sweetalert';
import { Formik } from 'formik';
import { sortBy } from 'lodash';

import Spinner from '../../components/spinner';
import Header from '../../components/pageHeader';
import { updateToken } from '../../helpers/utils';
import { SelectField } from '../../components/selectField';
import { isOwner } from '../../helpers/userHelper';
import { inviteUsers, sendInvitationLink, createUser, updateUserById, getShipperUserListById, getUserByEmail, deleteShipperUserById } from '../../services/index';

const UsersList = (props) => {
  const inviteUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string().email('Email is invalid.').required('Email is required.'),
    shipper: Yup.array()
      .min(1, 'Select at least one Shipper')
      .of(
        Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string(),
          })
          .required('Shipper is required')
      ),
  });

  const editUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
  });

  const inviteUser = {
    name: '',
    phone: '',
    email: '',
    title: '',
    shipper: [{ value: props?.shipper?.id, label: props?.shipper?.name }],
    role: 'viewer',
  };

  const [loading, showSpinner] = useState(false);
  const [users, setShipperUsers] = useState([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [currentEditUser, setCurrentEditUser] = useState({});
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  useEffect(() => {
    getUsers();
    getShippers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shipper]);

  const getShippers = async () => {
    const { shippers } = props;
    let shipperList = [];
    shippers?.length > 0 &&
      shippers.map((item) => {
        return shipperList.push({ value: item?.id, label: item?.name });
      });
    setShipperOptions(shipperList);
  };

  const getUsers = async () => {
    const {
      shipper: { id },
    } = props;
    showSpinner(true);
      const response = await getShipperUserListById(id);
      if (response && (response.status === 200 || response.status === 201)) {
        setShipperUsers(response?.data);
        let userData = response?.data;
        userData.map((user) => {
          user.name = user.name?.toLowerCase() || '';
          return user;
        });
        userData = sortBy(response?.data, 'name');
        setShipperUsers(userData);
        showSpinner(false);
      } else {
        if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
          await updateToken();
        }
        if (response?.response?.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
        showSpinner(false);
      }
  };

  const handleInviteUser = async (user) => {
    const fixedUserData = { ...user };
    user.name = user.name.trim();
    user.email = user.email.toLowerCase().trim();
    delete user.shipper;
    let shipperIds = [];
    fixedUserData.shipper.map((item) => {
      return shipperIds.push(item.value);
    });
    toggleInviteModal();
    showSpinner(true);
    const response = await getUserByEmail(user.email.toLowerCase().trim());
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const dbUser = response?.data;
        const request = { role: user.role };
        const result = await inviteUsers(request, dbUser.id, shipperIds);
        if (result) {
          showSpinner(false);
          getUsers();
          toast.success(`${user.name}'s details has been updated`);
        }
      } else {
        if (response && response?.response?.status === 404) {
          const response = await createUser(user);
          if (response && (response.status === 201 || response.status === 200)) {
            const dbUser = response?.data;
            const request = { role: user.role };
            const result = await inviteUsers(request, dbUser.id, shipperIds);
            if (result) {
              getUsers();
              toast.success(`${user.name} has been invited.`);
            }
            showSpinner(false);
          }
          else if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
            await updateToken();
          } else if (response?.response?.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
          showSpinner(false);
          
        }
      }
    }
  };

  const handleEditUser = async (user) => {
    if (user.role) {
      setShowEditUserModal(!showEditUserModal);
      showSpinner(async (loading) => {
        loading = !loading;
        const request = {
          role: user?.role,
          name: user?.name?.trim(),
          title: user?.title,
          phone: user?.phone,
        };
        const response = await updateUserById(props.shipper.id, user.id, request);
        if (response && (response.status === 201 || response.status === 200)) {
          showSpinner(!loading);
          getUsers();
          toast.success(`${user.name ? user.name : user.email}'s details has been updated`);
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            await updateToken();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
          showSpinner(!loading);
        }
      });
    }
  };

  const handleReInvite = async (user) => {
    swal({
      title: `Resend Invitation`,
      text: `Are you sure that you want to resend the invite to ${user.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const request = {
          name: user?.name,
        };
        const response = await sendInvitationLink(props?.shipper?.id, user.id, request);
        if (response && (response.status === 201 || response.status === 200)) {
          getUsers();
          toast.success(`Invite has been sent to ${user.name}.`);
          showSpinner(false);
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            await updateToken();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      } else {
        showSpinner(false);
      }
    });
  };

  const doRemove = async (user) => {
    const name = user.name ? user.name : user.email;
    swal({
      title: `Remove ${name}`,
      text: `Are you sure that you want to remove ${name} from ${props.shipper.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(async (loading) => {
          loading = !loading;
          const response = await deleteShipperUserById(props.shipper.id, user.id);
          if (response && (response.status === 201 || response.status === 200)) {
            showSpinner(!loading);
            getUsers();
            toast.success(`${name} has been removed.`);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
            
          }
        });
      } else {
        showSpinner(false);
      }
    });
  };

  const toggleInviteModal = () => setShowInviteModal(!showInviteModal);

  const toggleEditUserModal = async (event, user = '') => {
    if (user) setCurrentEditUser({ ...user, role: user.roles.shipper[props.shipper.id] });
    setShowEditUserModal(!showEditUserModal);
  };

  const { shipper } = props;
  
  return (
    <Spinner display={loading}>
      <Header title={shipper?.name} name='Users'>
        {isOwner(shipper) && (
          <button type='button' class='btn btn-primary' onClick={toggleInviteModal}>
            <i className='fe fe-user-plus'></i> Invite User
          </button>
        )}
      </Header>

      <div className='container-fluid'>
        <div className='card'>
          {users && (
            <table className='table'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Title / Company</th>
                  <th className='text-center'>Role</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='list font-size-base'>
                {users?.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='text-capitalize'>{user.name}</div>
                        <div className='small text-muted'>{user.email}</div>
                        <div className='small text-muted'>{user.phone}</div>
                      </td>
                      <td>
                        {user.title}
                        <div className='small text-muted'>{user.company}</div>
                      </td>
                      <td className='text-center'>
                        {user.roles.shipper[props.shipper.id] === 'owner' && <span className='badge bg-danger-soft'>Owner</span>}
                        {user.roles.shipper[props.shipper.id] === 'editor' && <span className='badge bg-info-soft'>Editor</span>}
                        {user.roles.shipper[props.shipper.id] === 'viewer' && <span className='badge bg-light-soft'>Viewer</span>}
                      </td>
                      {isOwner(props.shipper) ? (
                        <td className='text-right'>
                          <div className='dropdown'>
                            <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                              <i className='fe fe-more-vertical'></i>
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                              <button
                                type='button'
                                className='dropdown-item'
                                onClick={(e) => {
                                  toggleEditUserModal(e, user);
                                }}>
                                Edit
                              </button>
                              <button
                                type='button'
                                className='dropdown-item'
                                onClick={() => {
                                  handleReInvite(user);
                                }}>
                                Resend Invite
                              </button>
                              <button
                                type='button'
                                className='dropdown-item text-danger'
                                onClick={() => {
                                  doRemove(user);
                                }}>
                                Remove
                              </button>
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* invite user */}
      <Modal show={showInviteModal} onHide={toggleInviteModal}>
        <Modal.Body>
          <Modal.Title className='h1'>Invite User</Modal.Title>
          <hr />
          <Formik initialValues={inviteUser} validationSchema={inviteUserSchema} onSubmit={handleInviteUser}>
            {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                  <label className='mb-2'>
                    Name<span className='text-danger'>*</span>
                  </label>
                  <input type='text' className='form-control' name='name' placeholder='e.g. John Doe' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                  {errors.name && <div className='text-danger mt-2 ms-1 h5'>{errors.name}</div>}
                </div>
                <div className='form-group'>
                  <label className='mb-2'>
                    Email<span className='text-danger'>*</span>
                  </label>
                  <input className='form-control' type='email' name='email' placeholder='e.g. john@phoxhealth.com' value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                  {errors.email && <div className='text-danger mt-2 ms-1 h5'>{errors.email}</div>}
                </div>
                <div className='form-group'>
                  <label className='mb-2'>
                    Shippers<span className='text-danger'>*</span>
                  </label>
                  <SelectField required error={errors.shipper} id='shipper' name='shipper' placeholder='Select' options={shipperOptions} value={values.shipper} isMulti={true} onChange={setFieldValue} isClearable={true} backspaceRemovesValue={true} />
                </div>
                <div className='form-group'>
                  <label className='mb-2'>Phone</label>
                  <input className='form-control' type='phone' name='phone' maxLength='10' placeholder='e.g. +1 983 331 3464' value={values.phone} onChange={handleChange} />
                </div>
                <div className='form-group'>
                  <label className='mb-2'>Title</label>
                  <input className='form-control' type='text' name='title' placeholder='e.g. Pharmacist' value={values.title} onChange={handleChange} isInvalid={!!errors.title} />
                </div>
                <div className='form-group'>
                  <label className='mb-2'>Role</label>
                  <select className='form-control' name='role' custom value={values.role} onChange={handleChange}>
                    <option value='viewer'>Viewer</option>
                    <option value='editor'>Editor</option>
                    <option value='owner'>Owner</option>
                  </select>
                </div>
                <div className='d-flex justify-content-end'>
                  <button type='button' class='btn btn-white' onClick={toggleInviteModal}>
                    Close
                  </button>
                  <button type='submit' className='btn btn-primary ms-2'>
                    Invite User
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* edit user */}
      <Modal show={showEditUserModal} onHide={toggleEditUserModal}>
        <Modal.Body>
          <Modal.Title className='h1'> Edit User</Modal.Title>
          <hr />
          <Formik initialValues={currentEditUser} validationSchema={editUserSchema} onSubmit={handleEditUser}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                  <label className='mb-2'>
                    Name<span className='text-danger'>*</span>
                  </label>
                  <input type='text' className='form-control' name='name' placeholder='e.g. John Doe' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                  {errors.name && <div className='text-danger mt-2 ms-1 h5'>{errors.name}</div>}
                </div>
                <div className='form-group'>
                  <label className='mb-2'>Phone</label>
                  <input className='form-control' type='phone' name='phone' maxLength='10' placeholder='e.g. +1 983 331 3464' value={values.phone} onChange={handleChange} />
                </div>
                <div className='form-group'>
                  <label className='mb-2'>Title</label>
                  <input className='form-control' type='text' name='title' placeholder='e.g. Pharmacist' value={values.title} onChange={handleChange} isInvalid={!!errors.title} />
                </div>

                <div className='form-group'>
                  <label className='mb-2'>Role</label>
                  <select className='form-control' as='select' name='role' custom value={values.role} onChange={handleChange}>
                    <option value='viewer'>Viewer</option>
                    <option value='editor'>Editor</option>
                    <option value='owner'>Owner</option>
                  </select>
                </div>
                <div className='d-flex justify-content-end'>
                  <button type='button' className='btn btn-white' onClick={toggleEditUserModal}>
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-primary ms-2'>
                    Update
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Spinner>
  );
};

const mapStateToProps = (state) => {
  return { shipper: state.shipper, shippers: state.shippers };
};
export default connect(mapStateToProps)(UsersList);
