import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Spinner from '../../components/spinner';

const Logout = (props) => {
  localStorage.clear();
  useHistory().push('/');

  return <Spinner display={true} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Logout);
