import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';
import { Formik } from 'formik';

import Spinner from '../../components/spinner';
import { resetPassword } from '../../services/auth';
import { trackPageView } from '../../helpers/analytics';

const ResetPassword = (props) => {
  const logo = props.logo;
  const token = `Bearer ${props.match.params.token}`;

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, 'Must contain 8 characters, one uppercase, one lowercase, one number and one special character'),
    confirmPassword: Yup.string().required('Password is required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const [loading, showSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  useEffect(() => {
    document.title = `Reset Password`;
    trackPageView();
  }, []);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const updatePassword = async (event) => {
    const { password } = event;
    showSpinner(true);
    const request = { password: password };
    const response = await resetPassword(request, token);
    if (response && (response.status === 200 || response.status === 201)) {
      toast.info(`Your password has been updated, please login to continue`);
      showSpinner(false);
      props.history.push('/');
    } else {
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact support@phoxhealth.com.');
      }
      showSpinner(false);
      props.history.push('/');
    }
  };

  return (
    <>
      <div className='d-flex align-items-center bg-auth vh-100 bg-color-password' style={{ display: 'block' }}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col col-12 col-md-7 col-xl-5 border round-border bg-white'>
              <div className='text-center pt-4'>
                <img src={logo} className='img-fluid login-logo my-3' alt='logo' /> <hr />
              </div>
              <div className='m-4'>
                <div className='text-start'>
                  <h1 className='mb-1'>Reset Password</h1>
                  <p className='text-muted  mb-3'>Enter a secure password to reset</p>
                </div>
                <Spinner display={loading}>
                  <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={updatePassword}>
                    {({ handleChange, handleSubmit, values, errors }) => (
                      <form noValidate onSubmit={handleSubmit}>
                        <div className='form-group'>
                          <label className='form-label fw-bold'>New Password</label><span class="text-danger">*</span>
                          <div className='input-group input-group-merge'>
                            <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter your new password' className='form-control' value={values.password} onChange={handleChange} isInvalid={!!errors.password} />
                            <span className='input-group-text' onClick={togglePassword}>
                              <i className={showPassword ? 'fe fe-eye' : 'fe fe-eye-off'}></i>
                            </span>
                          </div>
                          {errors.password && <div className='text-danger mt-2 ms-1 h5'>{errors.password}</div>}
                        </div>

                        <div className='form-group'>
                          <label className='form-label fw-bold'>Confirm Password</label><span class="text-danger">*</span>
                          <div className='input-group input-group-merge'>
                            <input type={showConfirmPassword ? 'text' : 'password'} className='form-control' name='confirmPassword' placeholder='Enter your confirm password' value={values.confirmPassword} onChange={handleChange} isInvalid={!!errors.confirmPassword} />
                            <span className='input-group-text' onClick={toggleConfirmPassword}>
                              <i className={showConfirmPassword ? 'fe fe-eye' : 'fe fe-eye-off'}></i>
                            </span>
                          </div>
                          {errors.confirmPassword && <div className='text-danger mt-2 ms-1 h5'>{errors.confirmPassword}</div>}
                        </div>
                        <button type='submit' className='btn btn-block btn-primary mb-5 btn-login w-100'>
                          Reset Password
                        </button>
                        <div className='text-center'>
                          Remember your password? <Link to='/' className='signin-button'>Sign In</Link>
                        </div>
                      </form>
                    )}
                  </Formik>
                </Spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user, logo: state.tenant_logo };
};
export default connect(mapStateToProps)(ResetPassword);
