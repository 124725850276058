import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Footer from './footer';

import LeftMenu from './leftMenu';
import 'bootstrap-daterangepicker/daterangepicker.css';

const AdminLayout = (props) => {
  useIdleTimer({
    timeout: 1000 * 60 * 20,
    onIdle: () => {
      window.location = '/logout';
    },
  });

  return (
    <>
      <LeftMenu />
      <div className='main-content'>
        {props.children}
        <Footer />
      </div>
    </>
  );
};

export default AdminLayout;
