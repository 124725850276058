import React from 'react';

const CardWidget = ({ icon, title, children }) => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row align-items-center gx-0'>
          <div className='col'>
            <h6 className='text-uppercase text-muted mb-2'>{title}</h6>
            <span className='h2 mb-0'>{children}</span>
          </div>
          <div className='col-auto'>{icon != null && <span className={`h2 fe fe-${icon} text-muted mb-0`}></span>}</div>
        </div>
      </div>
    </div>
  );
};

export default CardWidget;
