import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';
import { Formik } from 'formik';

import Spinner from '../../components/spinner';
import { forgotPassword } from '../../services/auth';
import { trackPageView } from '../../helpers/analytics';

const ForgotPassword = (props) => {
  // const logo = props.logo;
  const initialValues = {
    email: '',
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid.').required('Email is required.'),
  });

  const [loading, showSpinner] = useState(false);

  useEffect(() => {
    document.title = `Forgot Password`;
    trackPageView();
  }, []);

  const sendPasswordResetEmail = async (event) => {
    const { email } = event;
    showSpinner(true);
    const request = { email: email.toLowerCase().trim(), source: 'shipper' };
    const response = await forgotPassword(request);
    if (response && (response.status === 200 || response.status === 201)) {
      toast.info(`An email as been sent to ${email} with instructions to reset your password.`);
      showSpinner(false);
      props.history.push('/');
    } else {
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact support@phoxhealth.com.');
      }
      showSpinner(false);
      props.history.push('/');
    }
  };

  return (
    <>
      <div className='d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100 bg-color-password' style={{ display: 'block' }}>
        <div className='container'>
          <div className='row justify-content-center'> 
            <div className='col col-12 col-md-7 col-xl-5 my-3 bg-white py-4 px-5 forgot-pw-content'>
              <div className='text-center'>
                <img src="/img/logos/phoxhealth-logo2.png" className='img-fluid my-3' alt='logo' />
                <hr/>
                <div className='text-start'>
                <h1 className='mb-1'>Forgot Password</h1>
                <p className='text-muted mb-3 small'>Enter your registered email to get reset instructions</p>
                </div>
              </div>
              <Spinner display={loading}>
                <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={sendPasswordResetEmail}>
                  {({ handleChange, handleSubmit, values, errors }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div className='form-group'>
                        <label className='mb-2 fw-bold'>
                          Email Address<span className='text-danger'>*</span>
                        </label>
                        <input type='email' className='form-control' name='email' placeholder='email@phoxhealth.com' value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                        {errors.email && <div className='text-danger mt-2 ms-1 h5'>{errors.email}</div>}
                      </div>
                      <button type='submit' className='btn btn-block btn-primary mb-5 btn-login w-100'>
                        Continue
                      </button>
                      <div className='text-center'>
                        Remember your password? <Link to='/' className='signin-button'>Sign In</Link>
                      </div>
                    </form>
                  )}
                </Formik>
              </Spinner>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user, logo: state.logo };
};
export default connect(mapStateToProps)(ForgotPassword);
