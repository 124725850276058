/*eslint no-unused-vars: 0*/
/* eslint-disable no-sequences */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import NumberFormat from 'react-number-format';

import moment from 'moment';
import momentTz from 'moment-timezone';

import Header from '../../components/pageHeader';
import Spinner from '../../components/spinner';
import CardWidget from '../../components/cardWidget';

import { getData } from '../../services/index';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { toUtcDate, toLocalDate, updateToken } from '../../helpers/utils';

import { Chart as ChartJS } from 'chart.js/auto';
import { Chart, Bar } from 'react-chartjs-2';

const shipmentByDayChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const Dashboard = (props) => {
  const [dateFilters, setDateFilters] = useState({
    fromDate: `${moment().subtract(13, 'days').format('YYYY-MM-DD')}T00:00:00`,
    toDate: `${moment().format('YYYY-MM-DD')}T23:59:59`,
  });

  const timeZone = `${momentTz.tz.guess()}`;

  const [shipperId, setShipperId] = useState('');
  const [averageNPS, setAverageNPS] = useState(0);
  const [feedbackList, setFeedback] = useState([]);
  //const [averageTime, setAverageTime] = useState(0);
  //const [averageDistance, setAverageDistance] = useState(0);

  const [shipmentCount, setShipmentCount] = useState(0);
  const [shipmentByDay, setShipmentByDay] = useState({ labels: [], datasets: [{ data: [] }] });
  const [shipperFilter, setShipperFilter] = useState('');

  const [averageFeedback, setAverageFeedback] = useState(0);

  useEffect(() => {
    const getData = async () => {
      getShipmentCount();
      getAverageFeedback();
      getAverageNPS();
      // getAverageTime();
      // getAverageDistance();
      getShipmentByDay();
      getFeedback();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilters.fromDate, dateFilters.toDate, shipperId]);

  const getShipmentCount = async () => {
    const allShippersCount = `dashboard/shipment-count?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    const activeShipperCount = `dashboard/shipment-count?shipper=${shipperId}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    const url = shipperFilter?.length > 0 ? activeShipperCount : allShippersCount;
    const response = await getData(url);
    if (response && (response.status === 200 || response.status === 201)) {
      setShipmentCount(response?.data.count);
    } else {
      if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
        await updateToken();
      }
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
      }
    }
  };

  const getAverageFeedback = async () => {
    const allShippersFeedback = `dashboard/feedback-average?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    const activeShipperFeedback = `dashboard/feedback-average?shipper=${shipperId}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    const url = shipperFilter?.length > 0 ? activeShipperFeedback : allShippersFeedback;
    const response = await getData(url);
    if (response && (response.status === 200 || response.status === 201)) {
      setAverageFeedback(response?.data.avg);
    }
  };

  const getAverageNPS = async () => {
    const allShippersNPS = `dashboard/feedback-nps-average?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    const activeShipperNPS = `dashboard/feedback-nps-average?shipper=${shipperId}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
    const url = shipperFilter?.length > 0 ? activeShipperNPS : allShippersNPS;
    const response = await getData(url);
    if (response && (response.status === 200 || response.status === 201)) {
      setAverageNPS(Math.round(Number(response?.data.nps) * 100));
    }
  };

  // const getAverageTime = async () => {
  //   const allShippersAverageTime = `dashboard/shipment-delivery-time-average?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
  //   const activeShipperAverageTime = `dashboard/shipment-delivery-time-average?shipper=${shipperId}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
  //   const url = shipperFilter?.length > 0 ? activeShipperAverageTime : allShippersAverageTime;
  //   const response = await getAverageData(url);
  //   if (response) {
  //     if (response.status === 200 || response.status === 201) {
  //       const data = response?.data?.avg;
  //       if (data) {
  //         const averageTime = data?.hours && data?.minutes ? `${data?.hours} hr ${data?.minutes} min` : data?.minutes ? `${data?.minutes} min` : 0;
  //         setAverageTime(averageTime);
  //       } else {
  //         setAverageTime(0);
  //       }
  //     } else {
  //       if (response.response.status >= 500) {
  //         toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
  //       }
  //     }
  //   }
  // };

  // const getAverageDistance = async () => {
  //   const allShippersAverageDistance = `dashboard/shipment-distance-average?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
  //   const activeShipperAverageDistance = `dashboard/shipment-distance-average?shipper=${shipperId}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}`;
  //   const url = shipperFilter?.length > 0 ? activeShipperAverageDistance : allShippersAverageDistance;
  //   const response = await getAverageData(url);
  //   if (response) {
  //     if (response.status === 200 || response.status === 201) {
  //       setAverageDistance(Math.round(Number(response?.data.avg)));
  //     } else {
  //       if (response.response.status >= 500) {
  //         toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
  //       }
  //     }
  //   }
  // };

  const getShipmentByDay = async () => {
    const allShippersShipmentByDay = `dashboard/shipment-by-day?from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&status=delivered&time_zone=${timeZone}`;
    const activeShipperShipmentByDay = `dashboard/shipment-by-day?shipper=${shipperId}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&status=delivered&time_zone=${timeZone}`;
    const url = shipperFilter?.length > 0 ? activeShipperShipmentByDay : allShippersShipmentByDay;
    const response = await getData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let labels = [];
        const shipmentByDayData = Object.entries(response?.data)
          .sort()
          .reduce((o, [k, v]) => ((o[k] = v), o), {});
        for (let date of Object.keys(shipmentByDayData)) {
          labels.push(toLocalDate(date, 'll'));
        }
        const data = {
          labels: labels,
          datasets: [
            {
              label: 'Shipments (Delivered)',
              data: Object.values(shipmentByDayData),
              backgroundColor: '#2c7be5',
              borderWidth: 0.1,
              borderRadius: 12,
              barPercentage: 0.5,
              barThickness: 16,
              maxBarThickness: 18,
            },
          ],
        };
        setShipmentByDay(data);
      }
    }
  };

  const getFeedback = async () => {
    const allShippersFeedbacks = `shipment/feedback?&status=delivered&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=5`;
    const activeShipperFeedbacks = `shipment/feedback?shipper=${shipperId}&status=delivered&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=5`;
    const url = shipperFilter?.length > 0 ? activeShipperFeedbacks : allShippersFeedbacks;
    const response = await getData(url);
    if (response && (response.status === 200 || response.status === 201)) {
      setFeedback(response?.data);
    }
  };

  const handleDateChange = (event, picker) => {
    const fromDate = `${moment(picker.startDate).format('YYYY-MM-DD')}T00:00:00`;
    const toDate = `${moment(picker.endDate).format('YYYY-MM-DD')}T23:59:59`;
    setDateFilters({ fromDate, toDate });
  };

  return (
    <Spinner>
      <Header title={shipperFilter} name='Delivery Performance'>
        <div className='row'>
          <div className='col'></div>
          <div className='col'>
            <div className='dropdown'>
              <button className='btn btn-sm btn-light dropdown-toggle' type='button' id='dropdownShipper' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                {shipperFilter || 'All Locations'}
              </button>
              <div className='dropdown-menu' aria-labelledby='dropdownShipper'>
                <button
                  className='dropdown-item'
                  onClick={() => {
                    setShipperFilter('');
                    setShipperId('');
                  }}>
                  All Locations
                </button>
                {props.shippers &&
                  props.shippers.map((shipper, i) => {
                    return (
                      <button
                        key={i}
                        className='dropdown-item'
                        onClick={() => {
                          setShipperFilter(shipper?.alias || shipper?.name);
                          setShipperId(shipper.id);
                        }}>
                        {shipper.name}
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className='col-auto'>
            <DateRangePicker
              onEvent={handleDateChange}
              initialSettings={{
                startDate: moment().subtract(13, 'days'),
                endDate: moment(),
                linkedCalendars: true,
                showCustomRangeLabel: true,
                showDropdowns: true,
                alwaysShowCalendars: true,
                opens: 'left',
                ranges: {
                  'Today': [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                  'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                },
              }}>
              <input className='btn btn-sm btn-light' />
            </DateRangePicker>
          </div>

        </div>
      </Header>

      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <CardWidget title='Deliveries' icon='package'>
              {shipmentCount}
            </CardWidget>
          </div>
          <div className='col'>
            <CardWidget title='Delivery Feedback' icon='star'>
              <NumberFormat value={averageFeedback ? averageFeedback : 0} displayType={'text'} decimalScale={1}></NumberFormat>
            </CardWidget>
          </div>
          <div className='col'>
            <CardWidget title='Net Promoter Score' icon='check-circle'>
              <NumberFormat value={averageNPS} displayType={'text'} decimalScale={1}></NumberFormat>
            </CardWidget>
          </div>
        </div>

        {/* <div className='row d-none'>
          <div className='col'>
            <CardWidget title='Average Distance' icon='compass'>
              {`${averageDistance} mi`}{' '}
            </CardWidget>
          </div>
          <div className='col'>
            <CardWidget title='Order to Pickup' icon='clock'>
              {averageTime}
            </CardWidget>
          </div>
          <div className='col'>
            <CardWidget title='Pickup to Delivery' icon='clock'>
              {averageTime}
            </CardWidget>
          </div>
        </div> */}

        <div className='row'>
          <div className='col-lg-8'>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-header-title'>Daily Deliveries</h4>
              </div>
              <div className='card-body'>
                <Bar
                  width={150}
                  height={400}
                  type={'bar'}
                  data={shipmentByDay}
                  options={{
                    scales: {
                      xAxes: [
                        {
                          barThickness: 18, // number (pixels) or 'flex'
                          maxBarThickness: 20, // number (pixels)
                        },
                      ],
                    },
                    maintainAspectRatio: false,
                    cornerRadius: 10,
                  }}
                />
                {/* <Bar width={100} height={50} data={shipmentByDay} options={shipmentByDayChartOptions} /> */}
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='card'>
              <div className='card-header'>
                <h4 className='card-header-title'>Delivery Feedback</h4>
              </div>
              <div className='card-body py-0'>
                <div className='list-group list-group-flush'>
                  {feedbackList.map((feedback, index) => {
                    return (
                      <div className='list-group-item' key={index}>
                        <StarRatings rating={feedback.rating} starRatedColor='rgb(246,195,67)' starDimension='16px' starSpacing='1px' numberOfStars={5} name='rating' />
                        <p className='mt-2 mb-1'>{feedback.user_name}</p>
                        <div className='text-muted'>{feedback.comment}</div>
                        <div className='small mt-3'>{moment(toLocalDate(feedback.create_date, 'lll')).fromNow()}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spinner>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
    shippers: state.shippers,
  };
};

export default connect(mapStateToProps)(Dashboard);
