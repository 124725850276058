/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';
import { Formik } from 'formik';
import Resizer from 'react-image-file-resizer';

import Avatar from '../../components/avatar';
import Spinner from '../../components/spinner';
import Header from '../../components/pageHeader';
import CropModal from '../../components/cropModal';
import CardTitle from '../../components/cardTitle';
import { checkExtension, updateToken } from '../../helpers/utils';
import { getUserProfileData, updateUserProfile, updateUserProfileData } from '../../services/index';

const Profile = (props) => {
  const profileSchema = Yup.object().shape({
    name: Yup.string().required('name is required.'),
    phone: Yup.string().min('10', 'Must be 10 numbers'),
  });

  const [profileData, setProfileData] = useState({ id: '', name: '', phone: '', email: '', company: '', title: '' });

  const imgRef = useRef(null);
  const [src, setSrc] = useState();
  const [file, setFile] = useState('');
  const [loading, showSpinner] = useState(false);
  const [modalCrop, setCropModal] = useState(false);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [image, setImage] = useState({ imageLoading: false, imageUrl: '', croppedImage: '' });
  const [crop, setCrop] = useState({ unit: '%', width: 25, height: 25, aspect: 1 / 1 });

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }
    getCroppedImg();
  }, [completedCrop]);

  const getUserData = () => {
    showSpinner(async (loading) => {
      loading = !loading;
        const response = await getUserProfileData();
        if (response && (response.status === 200 || response.status === 201)) {
          const user = response?.data;
          setProfileData({
            id: user.id,
            name: user.name,
            phone: user.phone,
            email: user.email,
            company: user.company,
            title: user.title,
          });
          setImage({ ...image, imageUrl: user.photo_url });
          showSpinner(!loading);
        } else {
          if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
            await updateToken();
          }
          if (response?.response?.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
          showSpinner(!loading);
        }
    });
  };

  const onEdit = async (formData) => {
    const { name, title, phone, company } = formData;
    try {
      const request = {
        name: name,
        phone: phone,
        company: company,
        title: title,
        photo_url: image.imageUrl,
      };
      await updateProfileData(request);
    } catch (error) {
      console.log(error);
      toast.error(`Failed to update ${name} profile`);
    }
  };

  const updateProfileData = async (request) => {
    const response = await updateUserProfileData(request);
    if (response && (response.status === 200 || response.status === 201)) {
      await getUserData();
      const updatedUserData = { ...props.user, photo_url: image.imageUrl };
      props.dispatch({ type: 'USER', payload: updatedUserData });
      toast.success(`${name} profile updated successfully`);
    } else {
      if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
        await updateToken();
      }
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
      }
    }
  };

  const onSelectFile = async ({ target: { files } }) => {
    if (files && files[0] && checkExtension(files[0])) {
      document.getElementById('browse-file-controller').value = null;
      toast.error('Only jpg/jpeg/jfif/gif and png files are allowed!');
      return;
    }
    if (files && files[0]) {
      let file = files[0];
      file = await resizeFile(file);
      await uploadImg(file);
      const reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result));
      reader.readAsDataURL(files[0]);
      setFile(file);
    }
  };

  const handleCropModal = async () => setCropModal(!modalCrop);

  const onImageLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const getCroppedImg = async () => {
    const image = imgRef.current;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
    canvas.toBlob(async (blob) => {
      if (!blob) {
        console.error('Canvas is empty');
        return;
      } else {
        setFile(blob);
      }
    }, 'image/jpeg');
  };

  const resizeFile = async (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        '500',
        '500',
        'PNG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'blob'
      );
    });
  };

  const uploadImg = async (file) => {
    let fileUrl = '';
    let fileData = new FormData();
    fileData.append('file', file);
    setImage({ ...image, imageLoading: true });
    const response = await updateUserProfile(fileData);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        fileUrl = response?.data.url;
      } else {
        showSpinner((loading) => {
          loading = false;
        });
      }
      setImage({ ...image, imageLoading: false, croppedImage: fileUrl });
    }
    return fileUrl;
  };

  const removeImage = async (e) => {
    e.preventDefault();
    setSrc('');
    setImage({ ...image, imageUrl: '' });
  };

  const getCropImg = async () => {
    const fileUrl = await uploadImg(file);
    if (fileUrl) storeImageUrl(fileUrl);
  };

  const storeImageUrl = (url) => {
    setImage({ ...image, imageUrl: url });
    // setCropModal(!modalCrop);
  };

  const { name } = profileData;
  const { imageLoading, imageUrl } = image;
  const cropModalProps = { src, crop, imageUrl, imageLoading: image.imageLoading, name: name, defaultName: 'User', onSelectFile, onImageLoad, onCropChange, getCropImg, onCropComplete, onImageUpload: onSelectFile };

  return (
    <Spinner display={loading}>
      <Header title='Phox Health' name='My Profile' />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col col-md-12 col-lg-8 order-2 order-lg-1'>
            <div className='card'>
              <div className='card-body'>
                <Formik enableReinitialize={true} initialValues={{ ...profileData }} validationSchema={profileSchema} onSubmit={onEdit}>
                  {({ handleChange, handleSubmit, values, errors }) => (
                    <div className='form' noValidate onSubmit={handleSubmit} id='profile-form'>
                      <div className='form-group'>
                        <label className='mb-2'>
                          Full Name<span className='text-danger'>*</span>
                        </label>
                        <input type='text' className='form-control' name='name' placeholder='e.g. John Doe' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                        {errors.name && <div className='text-danger mt-2 ms-1 h5'>{errors.name}</div>}
                      </div>
                      <div className='form-group'>
                        <label className='mb-2'>Title</label>
                        <input type='text' className='form-control' name='title' placeholder='e.g. Software Engineer' value={values.title} onChange={handleChange} />
                      </div>
                      <div className='form-group'>
                        <label className='mb-2'>Company</label>
                        <input type='text' className='form-control' name='company' placeholder='e.g. Phox Health' value={values.company} onChange={handleChange} />
                      </div>
                      <div className='form-group'>
                        <label className='mb-2'>Email</label>
                        <input type='email' className='form-control' name='email' placeholder='e.g. address@example.com' value={values.email} disabled={true} />
                      </div>
                      <div className='form-group'>
                        <label className='mb-2'>Phone</label>
                        <input type='text' className='form-control' name='phone' placeholder='Phone' maxLength='10' isInvalid={!!errors.phone} value={values.phone} onChange={handleChange} />
                        {errors.phone && <div className='text-danger mt-2 ms-1 h5'>{errors.phone}</div>}
                      </div>
                      <hr/>
                      <div className='d-flex justify-content-end'>
                        <Link to='/dashboard' className='btn btn-white me-2 '>
                          Cancel
                        </Link>
                        <button type='button' disabled={values?.name?.length<=0}  className='btn btn-primary' onClick={()=>onEdit(values)}>
                          Update
                        </button>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className='col col-lg-4 order-1 order-lg-2'>
            <div className='card'>
              <CardTitle title='Profile Picture' />
              <div className='card-body'>
                <Spinner display={imageLoading}>
                  <div className='text-center'>
                    <Avatar image={imageUrl} size='xxl' />
                  </div>
                </Spinner>
              </div>
              <div className='card-footer'>
                <div className='d-flex justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-outline-danger '
                    onClick={(e) => {
                      removeImage(e);
                    }}>
                    Remove
                  </button>
                  <button type='button' className='btn btn-primary ms-2' data-toggle='modal' data-target='#cropModal' onClick={handleCropModal}>
                    Upload
                  </button>
                </div>
                <div className='small text-muted text-center mt-2'>PNG or JPG no bigger than 1000px wide and tall.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='modal fade' id='cropModal' tabindex='-1' role='dialog' aria-labelledby='cropModalTitle' aria-hidden='true'>
        <CropModal {...cropModalProps} />
      </div>
    </Spinner>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(Profile);
