import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('ph-shipper-token')}`;

const getData = async (url) => {
  try {
    const data = await axios.get(url);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};

const getExistingBatchOrders = async (servicetype, orderCode, source, destination) => {
  try {
    const data = await axios.get(`shipment/batch/get?service_type=${servicetype}&order_code=${orderCode}&shipper_location=${source}&delivery_location=${destination}`);
    return data;
  } catch (err) {
    return err;
  }
};

const getDistance = async (location) => {
  try {
    const data = await axios.post(`shipment/distance/miles`, location);
    return data;
  } catch (err) {
    return err;
  }
};

const getDeliveryTimeSlotById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/timeslots`);
    return data;
  } catch (err) {
    return err;
  }
};

const getServiceTypeById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/service-type`);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultServiceType = async (id) => {
  try {
    const data = await axios.get(`shipper/default/service-type`);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultDeliveryTimeSlot = async (id) => {
  try {
    const data = await axios.get(`shipper/default/timeslots`);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersSettings = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/settings`);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteShipperSettingById = async (shipperId, id) => {
  try {
    const data = await axios.delete(`shipper/${shipperId}/settings/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersDefaultSettings = async () => {
  try {
    const data = await axios.get(`shipper/default/settings`);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShippersSettingsById = async (id, settings) => {
  try {
    const data = await axios.post(`shipper/${id}/settings`, settings);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersUsersById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/users`);
    return data;
  } catch (err) {
    return err;
  }
};

const addEditShipper = async (url, shipper) => {
  try {
    const data = await axios.post(url, shipper);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteShipperById = async (id) => {
  try {
    const data = await axios.delete(`shipper/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShipmentById = async (id, shipment) => {
  try {
    const data = await axios.post(`shipment/${id}`, shipment);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShipmentStatusById = async (url, shipment) => {
  try {
    const data = await axios.post(url, shipment);
    return data;
  } catch (err) {
    return err;
  }
};

const getShipmentsById = async (id) => {
  try {
    const data = await axios.get(`shipment/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};

const getHistoryById = async (id) => {
  try {
    const data = await axios.get(`shipment/${id}/status`);
    return data;
  } catch (err) {
    return err;
  }
};

const getCarriersList = async () => {
  try {
    const data = await axios.get(`carrier`);
    return data;
  } catch (err) {
    return err;
  }
};

const updateCarrierShipmentById = async (id, carrierId, request) => {
  try {
    const data = await axios.post(`shipment/${id}/carrier/${carrierId}`, request);
    return data;
  } catch (err) {
    return err;
  }
};

const getFeedbackById = async (id) => {
  try {
    const data = await axios.get(`shipment/${id}/feedback`);
    return data;
  } catch (err) {
    return err;
  }
};

const createUser = async (user) => {
  try {
    const data = await axios.post(`user`, user);
    return data;
  } catch (err) {
    return err;
  }
};

const inviteUsers = async (user, userId, shipperIds) => {
  try {
    const data = await axios.post(`shipper/users/${userId}/add?shipper=${shipperIds}`, user);
    return data;
  } catch (err) {
    return err;
  }
};

const getShipperUserListById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/users`);
    return data;
  } catch (err) {
    return err;
  }
};

const getUserByEmail = async (email) => {
  try {
    const data = await axios.get(`user/${email}`);
    return data;
  } catch (err) {
    return err;
  }
};
const deleteShipperUserById = async (id, userId) => {
  try {
    const data = await axios.delete(`shipper/${id}/users/${userId}`);
    return data;
  } catch (err) {
    return err;
  }
};

const updateShipperUserById = async (id, userId, shipper) => {
  try {
    const data = await axios.post(`shipper/${id}/users/${userId}`, shipper);
    return data;
  } catch (err) {
    return err;
  }
};

const updateUserById = async (id, userId, request) => {
  try {
    const data = await axios.post(`shipper/${id}/users/${userId}/update`, request);
    return data;
  } catch (err) {
    return err;
  }
};

const updateUserRoleById = async (id, userId, role) => {
  try {
    const data = await axios.post(`shipper/${id}/users/${userId}`, role);
    return data;
  } catch (err) {
    return err;
  }
};

const getUserProfileData = async () => {
  try {
    const data = await axios.get('me');
    return data;
  } catch (err) {
    return err;
  }
};

const updateUserProfileData = async (user) => {
  try {
    const data = await axios.post('me', user);
    return data;
  } catch (err) {
    return err;
  }
};

const updateUserProfile = async (file) => {
  try {
    const data = await axios.post('file', file);
    return data;
  } catch (err) {
    return err;
  }
};

const refreshToken = async () => {
  try {
    const data = await axios.post('auth/refresh');
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultStorageById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/storage-type`);
    return data;
  } catch (err) {
    return err;
  }
};

const sendInvitationLink = async (id, userId, request) => {
  try {
    const data = await axios.post(`shipper/${id}/users/${userId}/invite`, request);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultStorageType = async () => {
  try {
    const data = await axios.get(`shipper/default/storage-type`);
    return data;
  } catch (err) {
    return err;
  }
};

const getDefaultProductType = async (id) => {
  try {
    const data = await axios.get(`shipper/default/product-type`);
    return data;
  } catch (err) {
    return err;
  }
};

const getProductTypeById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/product-type`);
    return data;
  } catch (err) {
    return err;
  }
};

const getAddressById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/address`);
    return data;
  } catch (err) {
    return err;
  }
};

const createBatch = async (batch) => {
  try {
    const data = await axios.post(`shipment/batch/add`, batch);
    return data;
  } catch (err) {
    return err;
  }
};

const addAddressById = async (id, address) => {
  try {
    const data = await axios.post(`shipper/${id}/address`, address);
    return data;
  } catch (err) {
    return err;
  }
};

const updateAddressById = async (shipperId, id, address) => {
  try {
    const data = await axios.post(`shipper/${shipperId}/address/${id}`, address);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteAddressById = async (shipperId, id) => {
  try {
    const data = await axios.delete(`shipper/${shipperId}/address/${id}`);
    return data;
  } catch (err) {
    return err;
  }
};

const shipmentStatusList = async () => {
  try {
    const data = await axios.get(`shipment/status/all`);
    return data;
  } catch (err) {
    return err;
  }
};
const getPatientTrackingUrlById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/settings?key=url_tracking_portal`);
    return data;
  } catch (err) {
    return err;
  }
};

const getPatientTrackingUrl = async () => {
  try {
    const data = await axios.get(`shipper/default/settings?key=url_tracking_portal`);
    return data;
  } catch (err) {
    return err;
  }
};

const getBatchShipments = async (batch_number, status) => {
  try {
    const data = await axios.get(`shipment?batch_number=${batch_number}&status=${status}`);
    return data;
  } catch (err) {
    return err;
  }
};

const getPrinterTypeByShipperById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/settings?key=shipper_default_printer`);

    return data;
  } catch (err) {
    return err;
  }
};

const getPrinterTypeByShipper = async () => {
  try {
    const data = await axios.get(`shipper/default/settings?key=shipper_default_printer`);

    return data;
  } catch (err) {
    return err;
  }
};

const getOrderNumberSettingsById = async (id) => {
  try {
    const data = await axios.get(`shipper/${id}/settings?key=shipper_order_number`);
    return data;
  } catch (err) {
    return err;
  }
};

const getOrderNumberSettings = async () => {
  try {
    const data = await axios.get(`shipper/default/settings?key=shipper_order_number`);
    return data;
  } catch (err) {
    return err;
  }
};

const getShipmentFormData = async (id, keyword) => {
  try {
    const data = await axios.get(`/shipment/${id}/contact-search?keyword=${keyword}`);
    return data;
  } catch (err) {
    return err;
  }
};

const getShipmentData = async (url) => {
  try {
    // const URL = BASE_URL.concat(url);
    const data = await axios.get(url);
    return data;
  } catch (err) {
    return err;
  }
};

const getAllGroups = async () => {
  try {
    const data = await axios.get(`shipper/group/all`);
    return data;
  } catch (err) {
    return err;
  }
};

const getDashboardData = async (url) => {
  try {
    const data = await axios.get(url);
    return data;
  } catch (err) {
    return err;
  }
};

const getShippersByGroup = async (name) => {
  try {
    const data = await axios.get(`shipper?group_name=${name}`);
    return data;
  } catch (err) {
    return err;
  }
};



const validateAddress = async (req)=>{
  try {
    let response =   await axios.post('/endicia/validate_address', req)
    return response
  } catch (error) {
    return error
  }
}

const getUspsLabel= async(req)=>{
  try {
    const response =  await axios.post('endicia/postage_label', req)
    return response
  } catch (error) {
    return error
  }

}

export { getShippersByGroup, getDashboardData, getAllGroups, getShipmentData, getData, sendInvitationLink, getBatchShipments, getExistingBatchOrders, getPatientTrackingUrl, getPatientTrackingUrlById, getDistance, shipmentStatusList, inviteUsers, updateUserById, getServiceTypeById, getDefaultServiceType, getDefaultProductType, createBatch, getDefaultStorageType, updateAddressById, addAddressById, deleteAddressById, getProductTypeById, getAddressById, getDefaultStorageById, getDefaultDeliveryTimeSlot, updateUserProfile, getDeliveryTimeSlotById, getFeedbackById, getShipperUserListById, updateUserProfileData, getUserProfileData, addEditShipper, refreshToken, deleteShipperSettingById, updateShippersSettingsById, getShippersDefaultSettings, getShippersSettings, deleteShipperUserById, updateUserRoleById, updateShipperUserById, getShippersUsersById, getShippersById, deleteShipperById, updateShipmentById, updateShipmentStatusById, getHistoryById, getCarriersList, updateCarrierShipmentById, getShipmentsById, createUser, getUserByEmail, getPrinterTypeByShipperById, getPrinterTypeByShipper, getOrderNumberSettings, getOrderNumberSettingsById, getShipmentFormData,validateAddress,getUspsLabel };
