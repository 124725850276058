import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import { getDownloadURL, ref } from 'firebase/storage';

import { storage } from '../../firebaseConfig';
import Spinner from '../../components/spinner';
import { updateToken } from '../../helpers/utils';
import { getPatientTrackingUrl, getPatientTrackingUrlById, getPrinterTypeByShipperById, getShipmentsById } from "../../services";

const QRCode = require(`qrcode.react`);

class PrintQRCode extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shipment: {},
			qrCode: ``,
			loading: ``,
			logo: ``,
			printType: `Default`,
		};
	}

	async componentDidMount() {
		this.getShipmentData();
	}

	getPrinterType = async (shipperId) => {
		const response = await getPrinterTypeByShipperById(shipperId);

		if (response?.status === 200 && response?.data?.length > 0) {
			this.setState({ printType: response?.data[0]?.value });

			const printStyle = document.getElementById(`printer_style`);
			response?.data[0]?.value === `Zebra Laser` && (printStyle.href = `/css/printer/zebra-laser.css`);
			response?.data[0]?.value === `Zebra ZP 505` && (printStyle.href = `/css/printer/zebra-zp.css`);
		}
	};

	getShipmentData = async () => {
		const {
			match: {
				params: { id },
			},
		} = this.props;

		this.setState({ loading: true });

		const response = await getShipmentsById(id);

		if (response) {
			if (response.status === 200 || response.status === 201) {
				const shipmentData = response?.data;
      
				let qrCode = ``;

				qrCode = `${shipmentData?.order_number}^${shipmentData?.ship_to?.contact_name}^${shipmentData?.ship_to?.phone}^${shipmentData?.ship_to?.email}^${shipmentData?.ship_to?.address1}^${shipmentData.ship_to.city}^${shipmentData?.ship_to?.state}^${shipmentData?.ship_to?.postal_code}`;

				const { shipper_id } = response?.data;

				await this.getPrinterType(shipper_id);
				await this.getPatientTrackingDetails(shipper_id);

				this.setState({ qrCode, shipment: shipmentData, loading: false });
			} else {
				if (response?.response?.status === 401 || response?.response?.data === `Unauthorized`) {
					await updateToken();
				}

				if (response?.response?.status >= 500) {
					toast.error(`Oops! There was an error trying to process your request. Please try again or contact admin.`);
				}

				this.setState({ loading: false });
			}
		}
	};

	getPatientTrackingDetails = async (id) => {
		const response = await getPatientTrackingUrlById(id);

		if ((response.status === 200 || response.status === 201) && response?.data.length) {
			const domain = response?.data[0]?.value;

			await this.getShipperLogo(domain);
		} else {
			const response = await getPatientTrackingUrl();

			if ((response.status === 200 || response.status === 201) && response?.data) {
				const domain = response?.data[0]?.value;

				await this.getShipperLogo(domain);
			}
		}
	};

	getShipperLogo = async (baseurl) => {
		let hostname = baseurl?.split(`.`)[0];

		hostname = hostname.replace(`https://`, ``);
		hostname = hostname.replace(`http://`, ``);

		const default_favicon = `/logo/default.png`,
			path = hostname === `qa`
				? default_favicon
				: `/img/${hostname}/logo.png`;

		getDownloadURL(ref(storage, path))
			.then((url) => {
				this.setState({ logo: url }, () => {
					setTimeout(() => {
						this.setState({ loading: false });
					}, 1500);
				});
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	};
	
	render() {
		const {
			match: {
				params: { id },
			},
			tenant_logo,
		} = this.props,
			{ qrCode, shipment, loading, printType } = this.state;	
		
			const delivery_instructions_trim = (data)=>{
				let delivery_instructions_Arr = data?.split(',');
			    let new_delivery_instructions = delivery_instructions_Arr?.splice(0,1);
				return new_delivery_instructions?.join(',').toString();

			}

		return (		
			<Spinner display={loading}>
				<div className="container print-container">
					{

						<div className="border border-secondary border-2 rounded px-3">
							<div className="row border-bottom border-secondary border-2 py-2">
								<div className="col-7">
									{
										shipment?.ship_from && (
											<div>
												<div className="font-weight-bold">{shipment?.ship_from?.alias}</div>
												<div className='small'>{shipment?.ship_from?.address1}</div>
												<div className='small'>{shipment?.ship_from?.address2}</div>
												<div className='small'>
													{shipment?.ship_from?.city},{` `}{shipment?.ship_from?.state},{` `}{shipment?.ship_from?.postal_code}
												</div>
												<div className='small'>{shipment?.ship_from?.phone}</div>
											</div>
										)
									}
								</div>
								<div className="col d-flex flex-column align-items-end">
									<img src={tenant_logo} className="img-fluid" alt="logo" />
									<div className="display-3 mt-3">{`#${shipment?.number}`}</div>
								</div>
							</div>
							<div className='row border-bottom border-secondary border-2 text-center py-2'>
								<div className='col'>
									Shipment Date: {moment(shipment.estimated_delivery_date).format('DD-MMM-YYYY')}
								</div>
							</div>
							<div className="row">
								<div className="col-7">
									{
										shipment.ship_to && (
											<div className="text-left mt-2">
												<div className="font-weight-bold">
													{shipment?.ship_to?.secondary_contact_name
														? `${shipment?.ship_to?.contact_name} C/O ${shipment?.ship_to?.secondary_contact_name}`
														: shipment?.ship_to?.contact_name}
												</div>
												{shipment?.ship_to?.company_name && <div>{shipment?.ship_to?.company_name}</div>}
												<div className='small'>{shipment?.ship_to?.address1}</div>
												<div className='small'>{shipment?.ship_to?.address2}</div>
												<div className='small'>
													{shipment?.ship_to?.city},{` `}{shipment?.ship_to?.state},{` `}{shipment?.ship_to?.postal_code}
												</div>
												<div className='small'>{shipment?.ship_to?.phone}</div>
											</div>
										)
									}

									<div className='border-bottom border-secondary border-2 my-2'>

									</div>

									<div className="mt-0">
										<div className="font-weight-bold">Delivery Instructions</div>
										<div className="small">{delivery_instructions_trim(shipment?.delivery_instructions)}</div>

									</div>
									{
										shipment?.signature_required && (
											<div className="d-inline-block mt-2">
												<img src={`/img/signature.png`} className="img-fluid d-inline-block pe-2" alt="logo" height="25" width="25" />
												<h6 className="me-3 d-inline-block font-weight-bold">SIGNATURE REQUIRED</h6>
											</div>
										)
									}
									{
										shipment?.refrigeration_required && (
											<div className="d-inline-block mt-2">
												<img src={`/img/coldchain.png`} className="ms-3 img-fluid d-inline-block pe-2" alt="logo" height="25" width="25" />
												<h6 className="d-inline-block font-weight-bold">COLD CHAIN</h6>
											</div>
										)
									}
								</div>
								<div className="col d-flex flex-column align-items-center mt-4">
									<QRCode value={qrCode} size={150} />
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-center my-2">
								<img src={`/img/logo.png`} className="img-fluid" alt="logo" width='80px' />
							</div>
						</div>
					}
					<div className='text-center'>
						<div className="d-none d-print-block mt-4 print--footer__display" />
						<div className="text-right mt-4 d-print-none">
							<Link className="btn btn-sm btn-white" to={`/shipment/${id}/overview`}>
								Cancel
							</Link>
							<button className="btn btn-sm btn-primary ms-2" onClick={() => window.print()}>
								<i className='fe fe-printer'></i> Print {printType}
							</button>
						</div>
					</div>
				</div>
			</Spinner>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	tenant_logo: state.tenant_logo,
});

export default connect(mapStateToProps)(PrintQRCode);
