import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Spinner from '../../components/spinner';
import { resetPassword, forgotPassword } from '../../services/auth';

const Join = (props) => {
  const logo = props.logo;
  const token = `Bearer ${props.match.params.token}`;
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common['Authorization'] = token;

  const [user, setUser] = useState({
    password: '',
    confirmPassword: '',
  });

  const initialValues = {
    email: '',
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid.').required('Email is required.'),
  });

  const schema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, 'Must contain 8 characters, one uppercase, one lowercase, one number and one special character'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const [loading, showSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLinkExpired, setLinkExpired] = useState(false);

  useEffect(() => {
    axios.get('me').then(async (response) => {
      setUser(response?.data);
    });
  }, []);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const updateProfile = async (event) => {
    const { password } = event;
    showSpinner(true);
    const request = { password: password };
    const response = await resetPassword(request, token);
    if (response && (response.status === 200 || response.status === 201)) {
      toast.info(`Your password has been updated, please login to continue`);
      showSpinner(false);
      setLinkExpired(false);
      props.history.push('/');
    } else {
      if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
        setLinkExpired(true);
        toast.warn('Oops! The Link to setup password has been expired, Try requesting again,');
      }
      if (response?.response?.status >= 500) {
        setLinkExpired(false);
        toast.error('Oops! There was an error trying to process your request. Please try again or contact support@phoxhealth.com.');
      }
      showSpinner(false);
    }
  };

  const sendPasswordResetEmail = async (event) => {
    const { email } = event;
    showSpinner(true);
    const request = { email: email.toLowerCase().trim(), source: 'shipper' };
    const response = await forgotPassword(request);
    if (response && (response.status === 200 || response.status === 201)) {
      toast.info(`An email as been sent to ${email} with instructions to reset your password.`);
      showSpinner(false);
      props.history.push('/');
    } else {
      showSpinner(false);
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact support@phoxhealth.com.');
      }
      props.history.push('/');
    }
  };

  return (
    <>
      <div className='d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100' style={{ display: 'block' }}>
        <div className='container'>
          {isLinkExpired ? (
            <div className='row justify-content-center'>
              <div className='col col-12 col-md-5 col-xl-4 my-5'>
                <div className='text-center'>
                  <img src={logo} className='img-fluid login-logo mb-5' alt='logo' />
                  <h1 className='display-4 mb-3'>Welcome!</h1>
                  <p className='text-muted  mb-5'>Enter your email to get instructions to setup password.</p>
                </div>
                <Spinner display={loading}>
                  <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={sendPasswordResetEmail}>
                    {({ handleChange, handleSubmit, values, errors }) => (
                      <form noValidate onSubmit={handleSubmit}>
                        <div className='form-group'>
                          <label className='mb-2'>
                            Email<span className='text-danger'>*</span>
                          </label>
                          <input type='email' className='form-control' name='email' placeholder='email@phoxhealth.com' value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                          {errors.email && <div className='text-danger mt-2 ms-1 h5'>{errors.email}</div>}
                        </div>

                        <button type='submit' className='btn btn-lg btn-block btn-primary mb-3 btn-login'>
                          Continue
                        </button>
                        <div className='text-center text-muted small'>
                          Remember your password? <Link to='/'>Sign In</Link>
                        </div>
                      </form>
                    )}
                  </Formik>
                </Spinner>
              </div>
            </div>
          ) : (
            <div className='row justify-content-center'>
              <div className='col col-12 col-md-5 col-xl-4 my-5'>
                <div className='text-center'>
                  <img src={logo} className='img-fluid mb-5' alt='logo' />
                  <h1 className='display-4 mb-3'>Welcome!</h1>
                  <p className='text-muted  mb-5'>Complete your profile to continue</p>
                </div>
                <Spinner display={loading}>
                  <Formik initialValues={user} validationSchema={schema} onSubmit={updateProfile}>
                    {({ handleChange, handleSubmit, values, errors }) => (
                      <form noValidate onSubmit={handleSubmit}>
                        <div className='form-group'>
                          <div className='input-group input-group-merge'>
                            <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter Password' className='form-control' value={values.password} onChange={handleChange} isInvalid={!!errors.password} />
                            <span className='input-group-text' onClick={togglePassword}>
                              <i className={showPassword ? 'fe fe-eye' : 'fe fe-eye-off'}></i>
                            </span>
                          </div>
                          {errors.password && <div className='text-danger mt-2 ms-1 h5'>{errors.password}</div>}
                        </div>

                        <div className='form-group'>
                          <input type='password' className='form-control' name='confirmPassword' placeholder='Confirm password' value={values.confirmPassword} onChange={handleChange} isInvalid={!!errors.confirmPassword} />
                          {errors.confirmPassword && <div className='text-danger mt-2 ms-1 h5'>{errors.confirmPassword}</div>}
                        </div>

                        <button type='submit' className='btn btn-lg btn-block btn-primary mb-3 btn-login'>
                          Continue
                        </button>
                      </form>
                    )}
                  </Formik>
                </Spinner>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { logo: state.logo };
};
export default connect(mapStateToProps)(Join);
