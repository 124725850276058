import React from 'react';

const CardTitle = ({ title, children }) => {
  return (
    <div class='card-header'>
      <h4 className='card-header-title'>{title}</h4>
      {children}
    </div>
  );
};

export default CardTitle;
