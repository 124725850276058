/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import moment from 'moment';
import Spinner from '../../components/spinner';
import Header from '../../components/pageHeader';
import { StatusBadge } from '../../components/statusBadge';

import { getData } from '../../services/index';
import { toUtcDate, toLocalDate, getTAT, updateToken } from '../../helpers/utils';

const reportsHelper = require('../../helpers/appHelper');
let dayjs = require('dayjs');
let utc = require('dayjs/plugin/utc');
let localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(utc);
dayjs.extend(localizedFormat);

const Shipments = (props) => {
  const take = 100;
  const timeoutRef = useRef(null);

  const [timeout, updateTimeout] = useState(0);
  const [spinner, showSpinner] = useState(false);
  const [shipments, setShipments] = useState([]);

  const [skip, setSkip] = useState(0);
  const [status, setStatus] = useState('');
  const [keyword, setKeyword] = useState('');
  const [instruction, setInstruction] = useState('');
  const [dateFilters, setDateFilters] = useState({
    fromDate: `${moment().subtract(13, 'days').format('YYYY-MM-DD')}T00:00:00`,
    toDate: `${moment().format('YYYY-MM-DD')}T23:59:59`,
  });

  useEffect(() => {
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      getShipments();
    }, timeout);
  }, [props.shipper, skip, keyword, status, instruction, dateFilters.fromDate, dateFilters.toDate]);

  useEffect(() => {
    setPagination();
  }, [dateFilters.fromDate, dateFilters.toDate]);

  const switchShipper = (shipper) => {
    props.dispatch({ type: 'SHIPPER', payload: shipper });
  };

  const getShipments = async () => {
    const {
      shipper: { id },
    } = props;
    showSpinner(true);
    const searchKeyword = keyword?.length > 0 ? encodeURI(keyword?.trim()) : '';
    let url = `shipment?shipper=${id}&keyword=${searchKeyword}&status=${status.toLowerCase()}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}`;
    if (instruction === 'Refrigeration') url = `${url}&refrigeration_required=true`;
    if (instruction === 'Adult Signature') url = `${url}&signature_required=true`;
    if (instruction === 'Delivery Instructions') url = `${url}&special_instruction=true`;
    if (keyword?.length > 0) {
      url = `shipment?shipper=${id}&keyword=${searchKeyword}&status=${status?.toLowerCase()}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${take}&skip=${skip}`;
    }
    const response = await getData(url);
    if (response && (response.status === 200 || response.status === 201)) {
      setShipments(response?.data);
      updateTimeout(1200);
      showSpinner(false);
    } else {
      if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
        await updateToken();
      }
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
      }
      showSpinner(false);
    }
  };

  const getTotalShipmentsCounts = async () => {
    showSpinner(true);
    const url = 'dashboard/shipment-by-status';
    const response = await getData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shipmentCount = Object.values(response?.data).reduce((totalCount, count) => Number(totalCount) + Number(count), 0);
        const reports = await getReportData(shipmentCount);
        showSpinner(false);
        return reports;
      } else {
        showSpinner(false);
        if (response?.response?.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getReportData = async (count) => {
    const {
      shipper: { id },
    } = props;
    const searchKeyword = keyword?.length > 0 ? encodeURI(keyword?.toLowerCase().trim()) : '';
    let url = `dashboard/shipment-feedback?shipper=${id}&keyword=${searchKeyword}&status=${status.toLowerCase()}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${count}&skip=${skip}`;
    if (instruction === 'Refrigeration') url = `${url}&refrigeration_required=true`;
    if (instruction === 'Adult Signature') url = `${url}&signature_required=true`;
    if (instruction === 'Special Instruction') url = `${url}&special_instruction=true`;
    if (keyword?.length > 0) {
      url = `dashboard/shipment-feedback?shipper=${id}&keyword=${searchKeyword}&status=${status?.toLowerCase()}&from_date=${toUtcDate(dateFilters.fromDate)}&to_date=${toUtcDate(dateFilters.toDate)}&take=${count}&skip=${skip}`;
    }
    const response = await getData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        let reportData = response?.data.map((shipment) => {
          let deliveryProof = '',
            deliveryMessage = '';
          let isVerifiedWithOtp = false;
          const verificationCode = shipment?.verification_code;
          const isDelivered = shipment?.status === 'Delivered';
          if (isDelivered) {
            const message = shipment?.description;
            if (Array.isArray(shipment?.attachments) && shipment?.attachments?.length > 0) {
              let data = '';
              if (shipment?.attachments[0]?.includes('{"url"')) {
                data = JSON.parse(shipment?.attachments[0]);
                data = Object.values(data);
                deliveryProof = data && data[0];
              } else {
                data = shipment?.attachments[0];
                deliveryProof = data;
              }
              isVerifiedWithOtp = false;
            } else if (Number(message) === verificationCode) {
              deliveryMessage = `Verified with OTP - ${verificationCode}`;
              isVerifiedWithOtp = true;
            } else {
              deliveryMessage = message;
              isVerifiedWithOtp = false;
            }
          }
          const turnAroundTime = shipment.delivery_date && shipment.create_date ? `${getTAT(dayjs(shipment?.delivery_date).local().format('lll'), dayjs(shipment?.create_date).local().format('lll'))}` : '';
          shipment.TAT = turnAroundTime >= 0 ? Number(turnAroundTime) : null;
          shipment.delivery_proof = isVerifiedWithOtp ? (deliveryMessage?.length > 0 ? deliveryMessage : '') : deliveryProof?.length > 0 ? deliveryProof : deliveryMessage?.length > 0 ? deliveryMessage : '';
          shipment.parcel_count = Number(shipment?.parcels && shipment?.parcels.length > 0 && Object.values(shipment?.parcels[0])[0]?.length) || 0;
          shipment.nps = shipment?.nps?.length > 0 ? Number(shipment?.nps) : null;
          shipment.rating = shipment?.rating >= 0 ? shipment?.rating : null;
          shipment.distance = shipment?.distance ? Number(shipment?.distance) : 0;
          shipment.comment = shipment?.comment?.length > 0 ? shipment?.comment : '';
          shipment.pickup_date = shipment?.pickup_date ? toLocalDate(shipment?.pickup_date, 'MMMM D YYYY, h:mm a') : '';
          shipment.create_date = toLocalDate(shipment?.create_date, 'MMMM D YYYY, h:mm a');
          shipment.delivery_date = shipment?.delivery_date ? toLocalDate(shipment?.delivery_date, 'MMMM D YYYY, h:mm a') : '';
          shipment.out_for_return_date = shipment?.out_for_return_date ? toLocalDate(shipment?.out_for_return_date, 'MMMM D YYYY, h:mm a') : '';
          shipment.returned_date = shipment?.returned_date ? toLocalDate(shipment?.returned_date, 'MMMM D YYYY, h:mm a') : '';
          return shipment;
        });
        reportData = reportData.sort((a, b) => b.number - a.number);
        return reportData;
      }
    } else {
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
      }
    }
  };

  const generateReport = async (name) => {
    const reports = await getTotalShipmentsCounts();
    if (name === 'xls') {
      if (reports?.length > 0) {
        await reportsHelper.downloadShipmentExcelReport(reports, dateFilters);
      } else {
        toast.error('Sorry no data or something went wrong please try again');
      }
    } else if (name === 'csv') {
      if (reports?.length > 0) {
        await reportsHelper.downloadShipmentCsvReport(reports, dateFilters);
      } else {
        toast.error('Sorry no data or something went wrong please try again');
      }
    }
  };

  const pagePrevious = () => {
    setSkip(skip - take);
  };

  const pageNext = () => {
    setSkip(skip + take);
  };

  const onKeywordChange = async ({ target: { value } }) => {
    setSkip(0);
    setKeyword(value);
  };

  const setPagination = () => {
    if (shipments?.length <= 25) {
      setSkip(0);
    }
  };

  const clearFilters = () => {
    setSkip(0);
    setKeyword('');
    setStatus('');
    setInstruction('');
    const fromDate = `${moment().subtract(13, 'days').format('YYYY-MM-DD')}T00:00:00`;
    const toDate = `${moment().format('YYYY-MM-DD')}T23:59:59`;
    setDateFilters({ fromDate, toDate });
  };

  const { shipment_statuslist, delivery_instruction } = props;

  const onDateChange = (event, picker) => {
    const fromDate = `${moment(picker.startDate).format('YYYY-MM-DD')}T00:00:00`;
    const toDate = `${moment(picker.endDate).format('YYYY-MM-DD')}T23:59:59`;
    setDateFilters({ fromDate, toDate });
  };

  let shippersForUsps = ['metrohealth mail order pharmacy', 'metrohealth specialty pharmacy']



  return (
    <>
      <Header title={props.shipper.name} name='Shipments'>
        <Link to={shippersForUsps.includes(props.shipper.name.toLowerCase()) ? '/shipment/select-shipment-method' : '/shipment/create'} className='btn btn-primary'>
          New Shipment
        </Link>
      </Header>
      <div className='container-fluid'>
        <div className='card'>
          <div className='card-header'>
            <div className='row'>
              <div className='col'>
                <div className='input-group input-group-sm input-group-flush input-group-merge input-group-reverse'>
                  <input className='form-control list-search' type='text' placeholder='Search' value={keyword} onChange={onKeywordChange} />
                  <span className='input-group-text'>
                    <i className='fe fe-search'></i>
                  </span>
                </div>
              </div>
              <div className='col-auto px-1'>
                <div className='dropdown'>
                  <button className='btn btn-sm btn-light dropdown-toggle w-100 text-wrap' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    {props.shipper.name}
                  </button>
                  <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    <button
                      className='dropdown-item'
                      onClick={() =>
                        switchShipper({
                          alias: "All Locations",
                          id: "",
                          name: "All Locations",
                          role: "All Locations",
                        }
                        )
                      }>
                      All Locations
                    </button>

                    {props?.shippers?.map((shipper) => {
                      return (

                        <button
                          key={`menu_${shipper.id}`}
                          className='dropdown-item'
                          onClick={() => {
                            switchShipper(shipper);
                          }}>
                          {shipper?.name}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='col-auto px-1'>
                <div className='dropdown'>
                  <button className='btn btn-light btn-sm dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Status: {status.length > 0 ? (status === 'Exception' ? 'Cancelled' : status) : 'All'}
                  </button>
                  <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    {shipment_statuslist?.length > 0 &&
                      shipment_statuslist?.map((status) => {
                        return (
                          <button
                            key={`status_${status}`}
                            className='dropdown-item'
                            onClick={() => {
                              setStatus(status);
                            }}>
                            {status === 'Exception' ? 'Cancelled' : status}
                          </button>
                        );
                      })}
                    <hr className='my-1' />
                    <button
                      className='dropdown-item'
                      onClick={() => {
                        setStatus('');
                      }}>
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-auto px-1'>
                <div className='dropdown'>
                  <button className='btn btn-light btn-sm dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Instruction: {instruction.length > 0 ? instruction : 'All'}
                  </button>
                  <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    {delivery_instruction?.length > 0 &&
                      delivery_instruction?.map((instruction) => {
                        return (
                          <button
                            key={`instruction_${instruction}`}
                            className='dropdown-item'
                            onClick={() => {
                              setInstruction(instruction);
                            }}>
                            {instruction}
                          </button>
                        );
                      })}
                    <hr className='my-1' />
                    <button
                      className='dropdown-item'
                      onClick={() => {
                        setInstruction('');
                      }}>
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-auto px-1'>
                <DateRangePicker
                  initialSettings={{
                    startDate: moment().subtract(13, 'days'),
                    endDate: moment(),
                    linkedCalendars: true,
                    showCustomRangeLabel: true,
                    showDropdowns: true,
                    alwaysShowCalendars: true,
                    opens: 'left',
                    ranges: {
                      'All': [moment('08/01/2019'), moment()],
                      'Today': [moment(), moment()],
                      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                      'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    },
                  }}
                  onApply={onDateChange}>
                  <input className='btn btn-light btn-sm ml-2' style={{ cursor: 'pointer' }} />
                </DateRangePicker>
              </div>
              <div className='col-auto px-1'>
                <div className='dropdown'>
                  <button className='btn btn-light btn-sm dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                    Export
                  </button>
                  <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                    <button
                      className='dropdown-item'
                      onClick={() => {
                        generateReport('xls');
                      }}>
                      Xls
                    </button>
                    <button
                      className='dropdown-item'
                      onClick={() => {
                        generateReport('csv');
                      }}>
                      Csv
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-auto px-1'>
                <button className='btn btn-light btn-sm pb-0' onClick={clearFilters}>
                  <i className='fe fe-x-circle'></i>
                </button>
              </div>
            </div>
          </div>
          <Spinner display={spinner}>
            <table className='table table-hover table-sm mb-0'>
              <thead>
                <tr>
                  <th className='text-center'>Shipment #</th>
                  {props.shipper.id === "" && <th>Sender</th>}
                  <th>Recipient</th>
                  <th>Courier</th>
                  <th className='text-center'>Delivery Type</th>
                  <th className='text-center'>Due Date</th>
                  <th className='text-center'>Status</th>
                </tr>
              </thead>
              <tbody>
                {shipments?.length > 0 &&
                  shipments?.map((shipment, index) => {
                    const uspsMailClass = shipment.weight < 1.0 ? 'First Class' : 'Priority'
                    return (
                      <tr key={shipment.id} style={{ cursor: 'pointer' }} onClick={() => window.open(`shipment/${shipment.id}/overview`, '_blank')}>
                        <td className='text-center'>
                          {shipment.number}
                          {shipment.batch_number > 0 && <div className='small text-muted'>Batch #{shipment.batch_number}</div>}
                        </td>
                        {props?.shipper?.id === "" && <td>
                          <div className="">
                            {shipment?.shipper_name}
                            <div className="text-muted">{shipment?.ship_from?.city} , {shipment?.ship_from?.state}</div>
                          </div>
                        </td>}
                        <td>
                          {shipment.ship_to.contact_name}
                          <div className='small text-muted'>
                            {shipment.ship_to.city}, {shipment.ship_to.state}
                          </div>
                        </td>
                        <td>
                          {shipment.carrier?.name}
                          <div className='text-small text-muted'>{shipment.special_instruction}</div>
                        </td>
                        <td className='text-center'>
                          {shipment?.carrier?.alias === 'USPS' ? uspsMailClass : shipment.service_type}
                        </td>
                        <td className='text-center'>
                          {shipment?.carrier?.alias !== 'USPS' && <>
                            {shipment?.estimated_delivery_date && toLocalDate(shipment.estimated_delivery_date, 'LT')}
                            <div className='small text-muted'>{shipment?.estimated_delivery_date && toLocalDate(shipment.estimated_delivery_date, 'll')}</div>
                          </>}
                        </td>
                        <td className='text-center'>
                          <StatusBadge status={shipment.status} />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={6}>
                    <ul className='pagination pagination-sm mb-0 justify-content-center'>
                      <li className={`page-item ${skip <= 0 ? 'disabled' : ''}`}>
                        <button
                          className='page-link'
                          onClick={() => {
                            pagePrevious();
                          }}>
                          Previous
                        </button>
                      </li>
                      <li className='page-item disabled'>
                        <button className='page-link'>Page {skip / take + 1}</button>
                      </li>
                      <li className={`page-item ${shipments.length < take ? 'disabled' : ''}`}>
                        <button
                          className='page-link'
                          onClick={() => {
                            pageNext();
                          }}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Spinner>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shipment_statuslist: state.shipment_statuslist,
    delivery_instruction: state.delivery_instruction,
    user: state.user,
    shipper: state.shipper,
    shippers: state.shippers,
  };
};

export default connect(mapStateToProps)(Shipments);
