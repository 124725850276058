import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import * as msal from '@azure/msal-browser';

import Spinner from '../../components/spinner';
import { auth, auth_msal } from '../../services/auth';
import { setUserId, trackPageView } from '../../helpers/analytics';

toast.configure();

const Login = (props) => {
  const tenant = props.tenant_config;

  const loginData = {
    email: '',
    password: '',
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid.').required('Email is required.'),
    password: Yup.string().required('Password is required'),
  });

  const [loading, showSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.title = `Sign In | Shipper Portal | Phox Health `;
    trackPageView();
  }, []);

  const doEmailLogin = async (form) => {
    const { email, password } = form;
    showSpinner(true);
    const request = { email: email?.toLowerCase()?.trim(), password: password };
    const response = await auth(request);
    if (response && response.status === 200) {
      await doPostLogin(response?.data);
    } else {
      showSpinner(false);
      toast.error('Invalid email / password.');
    }
  };

  const doAzureLogin = async () => {
    showSpinner(true);
    const instance = new msal.PublicClientApplication({
      auth: tenant.msalConfig,
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    });

    const msalResponse = await instance.loginPopup({
      redirectUri: tenant?.msal?.redirectUri,
    });

    const account = msalResponse.account;
    let loginResponse = await auth_msal(account);
    if (loginResponse?.status === 200) {
      await doPostLogin(loginResponse?.data);
      showSpinner(false);
    } else {
      showSpinner(false);
      toast.error('Invalid creditionals.');
    }
  };

  const doPostLogin = async (user) => {
    setUserId(user.email);
    localStorage.setItem('ph-shipper-token', user.token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;
    await props.dispatch({ type: 'USER', payload: user });
    if (user?.shippers?.length > 0) {
      await props.dispatch({ type: 'SHIPPER', payload: user.shippers[0] });
      await props.dispatch({ type: 'SHIPPERS', payload: user.shippers });
      props.history.push('/dashboard');
    } else {
      await props.dispatch({ type: 'SHIPPER', payload: {} });
      await props.dispatch({ type: 'SHIPPERS', payload: [] });
      toast.error('Access denied, Please request admin for shipper access');
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const { tenant_logo, tenant_icon } = props;
  return (
    <Spinner display={loading}>
      <section className='main-card-container d-flex align-items-center'>
        <div className="container-fluid login-container">
          <div className="row d-flex">
            <div className="card-container my-auto col-lg-12 col-xl-6  ">
              <div className="card login-card-container mx-auto my-auto" >
                <div className='text-center mt-4 mb-2'>
                  <img src={tenant_logo} alt='logo' className='img-fluid card-header-logo'/>
                </div>
                <hr />
                <div className="card-body pt-0">
                  <div className="body-head px-1">
                    <div className="d-flex text-left ">
                      <h1 className='display-5 mb-0 fw-bold'>Sign In</h1>
                    </div>
                    <p className='text-muted mb-4 mt-0'>Medication Delivery Platform</p>
                  </div>
                  <div className="content px-2">
                    <Formik initialValues={loginData} validationSchema={loginSchema} onSubmit={doEmailLogin}>
                      {({ handleChange, handleSubmit, values, errors }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <div className='form-group'>
                            <label className='form-label fw-bold'>Email Address</label>
                            <input type='email' name='email' placeholder='name@phoxhealth.com' className='form-control' value={values.email} onChange={handleChange} />
                            <span className='valid-feedback'>{errors.email}</span>
                          </div>
                          <div className='form-group'>
                            <div className='row'>
                              <div className='col'>
                                <label className='form-label fw-bold'>Password</label>
                              </div>
                              <div className='input-group input-group-merge'>
                                <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter Password' className='form-control' value={values.password} onChange={handleChange} />
                                <span className='input-group-text' onClick={togglePassword}>
                                  <i className={showPassword ? 'fe fe-eye' : 'fe fe-eye-off'}></i>
                                </span>
                              </div>
                            </div>
                            <div className='col-auto d-flex justify-content-end text-left mt-2 '>
                              <Link to='/forgot-password' className='form-text small fs-4 mb-0 text-muted'>
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                          <button type='submit' className='btn btn-lg fs-3 w-100 btn-primary mb-3 mt-0'>
                            Sign In
                          </button>
                        </form>
                      )}
                    </Formik>
                    {tenant?.msalEnabled === true && (
                      <button className='btn btn-lg w-100 btn-white fs-3' onClick={doAzureLogin}>
                        <img src={tenant_icon} alt={tenant.name || 'icon'} height='20px' className='mx-2' />
                        Continue with {tenant?.name}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 my-auto mx-auto login-cover d-none d-xl-block">
              <div className='bg-cover' >
                <img src="/img/login-cover-new.png" alt="Login-cover" className='login-cover-img img-fluid w-75' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Spinner>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    tenant_logo: state.tenant_logo,
    tenant_config: state.tenant_config,
    tenant_icon: state.tenant_icon,
  };
};

export default connect(mapStateToProps)(Login);
