/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { ref, getDownloadURL } from 'firebase/storage';
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config';

import { storage } from './firebaseConfig';

import AppRoute from './components/appRoute';
import AdminLayout from './components/adminLayout';
import PublicLayout from './components/publicLayout';

import Login from './pages/auth/login';
import Logout from './pages/auth/logout';
import Join from './pages/auth/join';
import ResetPassword from './pages/auth/resetPassword';
import ForgotPassword from './pages/auth/forgotPassword';

import Dashboard from './pages/dashboard/dashboard';
import LiveTracker from './pages/dashboard/liveTracker';

import AddShipments from './pages/shipment/addShipments.js';
import ShipmentList from './pages/shipment/shipmentLists';
import ShipmentDetail from './pages/shipment/shipmentDetail';
import ShipmentPrint from './pages/shipment/print';

import FeedbackList from './pages/feedback/feedbackList';

import Users from './pages/users/users';
import Profile from './pages/profiles/profile';
import SelectShipmentMethod from './pages/shipment/SelectCouriorMethod';
// import AddUspsShipment from './pages/shipment/AddUspsShipment';
import NewUSPSShipment from './pages/shipment/newUSPSShipment';

const App = (props) => {
  const remoteConfig = getRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 3600;

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = () => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        //shipment status list
        let statusList = getValue(remoteConfig, 'shipment_status');
        statusList = Object.values(JSON.parse(statusList?._value));
        props.dispatch({ type: 'SHIPMENT_STATUSLIST', payload: statusList });

        //shipment instruction list
        let instructionList = getValue(remoteConfig, 'delivery_instructions');
        instructionList = Object.values(JSON.parse(instructionList?._value));
        props.dispatch({ type: 'DELIVERY_INSTRUCTIONS', payload: instructionList });

        //Tracking mapStyles
        let mapStyle = getValue(remoteConfig, 'tracking_mapstyle');
        mapStyle = JSON.parse(mapStyle?._value);
        props.dispatch({ type: 'MAP_STYLE', payload: mapStyle });

        // Tenant
        const host = window.location.hostname.toLowerCase();
        let hosts = getValue(remoteConfig, 'hosts');
        hosts = JSON.parse(hosts?._value);
        const tenant = hosts.hasOwnProperty(host) ? hosts[host] : 'default';
        props.dispatch({ type: 'TENANT', payload: tenant });

        //Tenant Logo
        getDownloadURL(ref(storage, `/img/${tenant}/logo.png`)).then((url) => {
          props.dispatch({ type: 'TENANT_LOGO', payload: url });
        });

        getDownloadURL(ref(storage, `/img/${tenant}/icon.png`)).then((url) => {
          document.getElementById('favicon').href = url;
          props.dispatch({ type: 'TENANT_ICON', payload: url });
        });

        // Tenant Config
        let tenant_config = getValue(remoteConfig, `tenant_config_${tenant}`);
        tenant_config = JSON.parse(tenant_config?._value);
        props.dispatch({ type: 'TENANT_CONFIG', payload: tenant_config });
      })
      .catch((err) => {
        console.log('remote config error', err);
      });
  };

  const { user } = props;
  const isAllowed = isEmpty(user);

  return (
    <Router>
      <Switch>
        <AppRoute exact path='/' layout={PublicLayout} component={Login} />
        <AppRoute exact path='/logout' layout={PublicLayout} component={Logout} />
        <AppRoute exact path='/forgot-password' layout={PublicLayout} component={ForgotPassword} />
        <AppRoute exact path='/reset-password/:token' layout={PublicLayout} component={ResetPassword} />
        <AppRoute exact path='/join/:token' layout={PublicLayout} component={Join} />
        {!isAllowed && <AppRoute exact path='/dashboard' layout={AdminLayout} component={Dashboard} />}
        {!isAllowed && <AppRoute exact path='/live-tracker' layout={PublicLayout} component={LiveTracker} />}
        {!isAllowed && <AppRoute exact path='/shipments' layout={AdminLayout} component={ShipmentList} />}
        {!isAllowed && <AppRoute exact path='/shipment/:id/overview' layout={AdminLayout} component={ShipmentDetail} />}
        {!isAllowed && <AppRoute exact path='/shipment/select-shipment-method' layout={AdminLayout} component={SelectShipmentMethod} />}
        {!isAllowed && <AppRoute exact path='/shipment/create' layout={AdminLayout} component={AddShipments} />}
        {!isAllowed && <AppRoute exact path='/shipment/create-usps-shipment' layout={AdminLayout} component={NewUSPSShipment} />}
        {!isAllowed && <AppRoute exact path='/shipment/:id/print' layout={PublicLayout} component={ShipmentPrint} />}
        {!isAllowed && <AppRoute exact path='/feedback' layout={AdminLayout} component={FeedbackList} />}

        {!isAllowed && <AppRoute exact path='/users' layout={AdminLayout} component={Users} />}
        {!isAllowed && <AppRoute exact path='/profile' layout={AdminLayout} component={Profile} />}
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    tenant: state.tenant,
    tenant_config: state.tenant_config,
    tenant_logo: state.tenant_logo,
    tenant_icon: state.tenant_icon,
    shipment_statuslist: state.shipment_statuslist,
    delivery_instruction: state.delivery_instruction,
    tracking_mapstyle: state.tracking_mapstyle,
  };
};
export default connect(mapStateToProps)(App);