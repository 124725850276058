import React from 'react';

const NPSBadge = ({ nps, size }) => {
  return (
    <div className={size || 'h3 mb-0'}>
      {nps <= 6 && <span className='badge bg-danger-soft'>{nps}</span>}
      {nps >= 7 && nps <= 8 && <span className='badge bg-warning-soft'>{nps}</span>}
      {nps >= 9 && nps <= 10 && <span className='badge bg-success-soft'>{nps}</span>}
    </div>
  );
};

export default NPSBadge;
