/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import { updateToken, toUtcDate, mapStyle } from '../../helpers/utils';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { getShipmentData, getAllGroups, getDashboardData, getShippersByGroup, shipmentStatusList } from '../../services/index';

import { collection, onSnapshot, where, query } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

const skip = 0;
const keywordFilter = '';

const TrackingDashboard = (props) => {
  const fromDate = `${moment().subtract(24, 'hours').format('YYYY-MM-DD')}T00:00:00`;
  const toDate = `${moment().format('YYYY-MM-DD')}T23:59:59`;

  const [count, setCount] = useState(0);
  const [, setGroups] = useState([]);
  const [points, setPoints] = useState([]);
  const [bounds, setBounds] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [activeMarker, setMarker] = useState({});
  const [driverInfo, setDriverInfo] = useState([]);
  const [shipperList, setShipperList] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [groupsFilter,] = useState('');
  const [shipperFilter, setShipperFilter] = useState('');
  const [selectedPlace, setSelectedPlace] = useState({});
  const [shipmentStatus, setShipmentStatus] = useState([]);
  const [selectedShipper, setSelectedShipper] = useState('');
  const [showingToInfoWindow, setToInfoWindow] = useState(false);
  const [showingDriverLocation, setDriverLocation] = useState(true);
  const [readyForPickupCount, setReadyForPickupCount] = useState(0);
  const [showingFromInfoWindow, setFromInfoWindow] = useState(false);
  const [showingDriverInfoWindow, setDriverInfoWindow] = useState(false);
  useEffect(() => {
    getGroups();
    getShipmentStatusList();
    setShipperList(props.shippers)
  }, []);

  useEffect(() => {
    getShippers();
  }, [statusFilter, shipperFilter, groupsFilter]);

  useEffect(() => {
    resetShippers();
  }, [groupsFilter]);

  const getShipmentStatusList = async () => {
    const response = await shipmentStatusList();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const statusList = response?.data?.filter((item) => item !== 'Info Received' && item !== 'Processing');
        const shipmentStatus = statusList?.map((item, index) => {
          return { id: index, name: item };
        });
        setShipmentStatus(shipmentStatus);
      } else {
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getShipmentCount = async (shipperId) => {
    const url = `dashboard/shipment-count?from_date=${toUtcDate(fromDate)}&to_date=${toUtcDate(toDate)}`;
    const response = await getShipmentData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        setCount(Number(response?.data.count));
        getShipments(Number(response?.data.count), shipperId);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getGroups = async () => {
    const response = await getAllGroups();
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const data = response?.data;
        let group = data.map((group, index) => ({
          id: index + 1,
          name: group,
        }));
        group = [...new Set(group)];
        setGroups(group);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
  };

  const getShipments = async (count, shipperId) => {
    let pointsToPlot = [];
    if (count > 0) {
      const shipper = selectedShipper?.length > 0 ? selectedShipper : shipperId?.length > 0 ? shipperId : 'none';
      const url = `shipment?group_name=${groupsFilter.toLowerCase()}&shipper=${shipper}&status=${statusFilter.toLowerCase()}&keyword=${keywordFilter.toLowerCase().trim()}&take=${count}&skip=${skip}&from_date=${toUtcDate(fromDate)}&to_date=${toUtcDate(toDate)}`;
      const response = await getDashboardData(url);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          let shipmentsList = response?.data;
          if (shipperFilter.length > 0) {
            shipmentsList = shipmentsList.filter((item) => item.shipper_name.toLowerCase() === shipperFilter.toLowerCase());
          }
          let driversList = shipmentsList.filter((item) => item?.driver_id);
          driversList = uniqBy(driversList, 'driver_id');
          let shipperData = shipmentsList.filter((item) => item.ship_from?.shipper_name);
          shipperData = uniqBy(shipperData, 'shipper_name');

          shipperData.forEach((shipment) => {
            pointsToPlot.push({
              lat: Number(shipment.ship_from.location?._latitude),
              lng: Number(shipment.ship_from.location?._longitude),
            });
          });
          shipmentsList.forEach((shipment) => {
            pointsToPlot.push({
              lat: Number(shipment.ship_to.location?._latitude),
              lng: Number(shipment.ship_to.location?._longitude),
            });
          });

          pointsToPlot = pointsToPlot.filter((item) => item.lat && item.lng);
          pointsToPlot = [...new Set(pointsToPlot)];
          setPoints(pointsToPlot);
          const bounds = new props.google.maps.LatLngBounds();
          pointsToPlot.forEach((point, i) => {
            bounds.extend(pointsToPlot[i]);
          });
          setBounds(bounds);
          const isOutForDelivery = (statusFilter === 'Out for Delivery' || statusFilter === '') && driversList && driversList.length > 0;
          setDriverLocation(isOutForDelivery);
          if (isOutForDelivery) {
            getDriverLocation(driversList, pointsToPlot);
          }
          setShipments(shipmentsList);
          setShippers(shipperData);
        } else {
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    }
  };

  const getDriverLocation = (driversList, pointsToPlot) => {
    onSnapshot(
      query(collection(db, 'driver_v2')),
      (dbDrivers) => {
        let data = [];
        let drivers = [];
        dbDrivers.forEach((driver) => {
          data.push(driver.data());
        });
        driversList.forEach((item) => {
          let driverDetails = data.find((x) => x.id === item.driver_id);
          if (driverDetails?.name) {
            let driverName = driverDetails?.name.split(' ');
            driverName = driverName[1] ? `${driverName[0]} ${driverName[1].charAt(0)}.` : `${driverName[0]}.`;
            driverDetails.name = driverName;
          }
          if (driverDetails) {
            drivers.push(driverDetails);
          }
        });
        drivers.forEach(async (driver) => {
          pointsToPlot.push({ lat: driver?.latitude, lng: driver?.longitude });
          onSnapshot(
            query(collection(db, 'shipments'), where('driver_id', '==', driver?.id)),
            (dbDelivery) => {
              let deliveries = [];
              dbDelivery.forEach((driver) => {
                deliveries.push(driver.data());
              });
              driver.activeDeliveries = deliveries?.length > 0 ? deliveries?.length : 0;
            },
            async (error) => {
              console.log(error);
            }
          );
        });
        pointsToPlot = pointsToPlot.filter((item) => item.lat && item.lng);
        pointsToPlot = [...new Set(pointsToPlot)];
        setPoints(pointsToPlot);
        setDriverInfo(drivers);
      },
      (error) => {
        console.log(error);
      }
    );
    const bounds = new props.google.maps.LatLngBounds();
    pointsToPlot.forEach((point, i) => {
      bounds.extend(pointsToPlot[i]);
    });
    setBounds(bounds);
  };

  const resetShippers = () => {
    setSelectedShipper('');
    setShipperFilter('');
  };

  const getShippers = async () => {
    const keywordFilter = '';
    const url = `shipper?keyword=${keywordFilter.toLowerCase().trim()}&take=${500}&skip=${0}`;
    const response = groupsFilter && groupsFilter.length > 0 ? await getShippersByGroup(groupsFilter.toLowerCase()) : await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const shippers = response?.data;
        let ShipperIds = [];
        // await setShipperList(shippers);
        if (groupsFilter?.length > 0 && shippers?.length <= 0) {

        } else {
          ShipperIds = shippers.map((shipper) => {
            return shipper.id;
          });
        }
        getShipmentCount(ShipperIds);
      } else {
        if (response.response.status === 401 || response.response.data === 'Unauthorized') {
          await updateToken();
        }
        if (response.response.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    }
    setToInfoWindow(false);
    setDriverInfoWindow(false);
    setFromInfoWindow(false);
  };

  const onMarkerClick = async (propData, marker, place) => {
    setMarker(marker);
    if (propData && place) {
      setSelectedPlace(place);
    }
    if (propData.name === 'shipmentFrom') {
      await getReadyForPickupShipments(place);
      setToInfoWindow(false);
      setDriverInfoWindow(false);
      await setFromInfoWindow(true);
    }
    if (propData.name === 'shipmentTo') {
      setFromInfoWindow(false);
      setDriverInfoWindow(false);
      setToInfoWindow(true);
    }
    if (propData.name === 'driver') {
      setFromInfoWindow(false);
      setToInfoWindow(false);
      setDriverInfoWindow(true);
    }
  };

  const getReadyForPickupShipments = async (place) => {
    const Status = 'Ready for Pickup';
    const url = `shipment?group_name=&shipper=${place?.shipper_id}&status=${Status.toLowerCase()}&keyword=&take=${count}&skip=${skip}&from_date=${toUtcDate(fromDate)}&to_date=${toUtcDate(toDate)}`;
    const response = await getDashboardData(url);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        const pickUPCount = response?.data?.length > 0 ? response?.data?.length : 0;
        setReadyForPickupCount(pickUPCount);
      }
    }
  };

  const onMapClicked = () => {
    if (showingFromInfoWindow) {
      setMarker(null);
      setFromInfoWindow(false);
    }
    if (showingToInfoWindow) {
      setMarker(null);
      setToInfoWindow(false);
    }
    if (showingDriverInfoWindow) {
      setMarker(null);
      setDriverInfoWindow(false);
    }
  };

  if (points <= 1) {
    const bounds = new props.google.maps.LatLngBounds();
    points.push({ lat: 47.5977494, lng: -122.2211718 });
    points.push({ lat: 47.6131746, lng: -122.48215 });
    points.forEach((point) => {
      bounds.extend(point);
    });
    setBounds(bounds);
  }

  return (
    <>
      <div>
        <div className="fixed-top vh-100">
          <Map styles={props.mapStyles} google={props.google} zoom={12} containerStyle={mapStyle} mapTypeControl={false} streetViewControl={false} bounds={bounds} onClick={onMapClicked}>
            {shipments.map((place, index) => {
              return (
                <Marker
                  name="shipmentTo"
                  icon={{
                    url: place.status === 'Returned' ? '/img/return_marker.png' : place.status === 'Out for Return' ? '/img/out_for_return.png' : place.status === 'Out for Delivery' ? '/img/out_for_delivery.png' : place.status === 'Delivered' ? '/img/delivered.png' : place.status === 'Exception' ? '/img/cancelled.png' : '/img/ready_for_pickup.png',
                  }}
                  className="labels"
                  key={index}
                  id={index}
                  position={{
                    lat: place.ship_to && Number(place.ship_to.location?._latitude),
                    lng: place.ship_to && Number(place.ship_to.location?._longitude),
                  }}
                  onClick={(propData, marker) => onMarkerClick(propData, marker, place)}
                />
              );
            })}
            <InfoWindow marker={activeMarker} visible={showingToInfoWindow}>
              <div>
                <h6 className="text-uppercase text-muted mb-2">Drop off Location</h6>
                {selectedPlace.ship_to && (
                  <div className="mt-3 text-dark">
                    <div>{selectedPlace.ship_to.company_name}</div>
                    <div>{selectedPlace.ship_to.contact_name}</div>
                    <div className="text-muted mt-2">
                      <div>{selectedPlace.ship_to.address1}</div>
                      <div>{selectedPlace.ship_to.address2}</div>
                      <div>{selectedPlace.ship_to.address3}</div>
                      <div>
                        {selectedPlace.ship_to.city}, {selectedPlace.ship_to.state} {selectedPlace.ship_to.postal_code}
                      </div>
                    </div>
                    <a href={`/shipment/${selectedPlace.id}/overview`} target='_blank' rel='noopener noreferrer'>
                      <div>Learn More</div>
                    </a>
                  </div>
                )}
              </div>
            </InfoWindow>
            {shippers.map((place, index) => {
              return (
                <Marker
                  icon={{
                    url: '/img/pharmacy_marker.png',
                  }}
                  name="shipmentFrom"
                  className="labels"
                  key={index}
                  id={index}
                  position={{
                    lat: place.ship_from && Number(place.ship_from.location?._latitude),
                    lng: place.ship_from && Number(place.ship_from.location?._longitude),
                  }}
                  onClick={(propData, marker) => onMarkerClick(propData, marker, place)}
                />
              );
            })}
            <InfoWindow marker={activeMarker} visible={showingFromInfoWindow}>
              <div>
                <h6 className="text-uppercase text-muted mb-2">Pickup Location</h6>
                {selectedPlace.ship_from && (
                  <div className="mt-3">
                    <div className="text-dark">{selectedPlace.ship_from.shipper_name}</div>
                    <div className="text-muted mt-2">
                      <div>{selectedPlace.ship_from.address1}</div>
                      <div>{selectedPlace.ship_from.address2}</div>
                      <div>{selectedPlace.ship_from.address3}</div>
                      <div>
                        {selectedPlace.ship_from.city}, {selectedPlace.ship_from.state} {selectedPlace.ship_from.postal_code}
                      </div>
                    </div>
                    <div className="font-weight-bold text-primary text mt-2">{`READY FOR PICKUP : ${readyForPickupCount}`}</div>
                  </div>
                )}
              </div>
            </InfoWindow>
            {showingDriverLocation &&
              driverInfo.map((place, index) => {
                return (
                  <Marker
                    icon={{
                      url: '/img/driver_marker.png',
                    }}
                    name="driver"
                    className="labels"
                    key={index}
                    id={index}
                    position={{
                      lat: place?.latitude,
                      lng: place?.longitude,
                    }}
                    onClick={(propData, marker) => onMarkerClick(propData, marker, place)}
                  />
                );
              })}
            <InfoWindow marker={activeMarker} visible={showingDriverInfoWindow}>
              <div>
                <h6 className="text-uppercase text-muted mb-2">Driver Location</h6>
                <div className="text-dark">{selectedPlace?.name}</div>
                <div className="font-weight-bold text-warning text mt-2">{`PENDING DELIVERIES : ${selectedPlace?.activeDeliveries}`}</div>
              </div>
            </InfoWindow>
          </Map>
        </div>
        <div className="fixed-top w-25 m-4 d-none d-lg-block">
          <div className='mt-3'>
            <Dropdown>
              <Dropdown.Toggle variant="white">Shipper{shipperFilter && <span>: {shipperFilter}</span>}</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShipperFilter('');
                    setSelectedShipper('');
                  }}
                >
                  All
                </Dropdown.Item>
                {shipperList &&
                  shipperList.map((shipper, i) => {
                    return (
                      <>
                        <Dropdown.Item
                          key={i}
                          onClick={() => {
                            setShipperFilter(shipper.name);
                            setSelectedShipper(shipper.id);
                          }}
                        >
                          {shipper.name}
                        </Dropdown.Item>
                      </>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='mt-3'>
            <Dropdown>
              <Dropdown.Toggle variant="white">
                Status
                {statusFilter && <span>: {statusFilter === 'Exception' ? 'Cancelled' : statusFilter}</span>}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setStatusFilter('');
                  }}
                >
                  All
                </Dropdown.Item>
                {shipmentStatus &&
                  shipmentStatus.map((shipment, i) => {
                    return (
                      <>
                        <Dropdown.Item
                          key={i}
                          onClick={() => {
                            setStatusFilter(shipment.name);
                          }}
                        >
                          {shipment.name === 'Exception' ? 'Cancelled' : shipment.name}
                        </Dropdown.Item>
                      </>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
    shippers: state.shippers,
  };
};

TrackingDashboard.defaultProps = {
  mapStyles: [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'all',
      stylers: [
        {
          saturation: 0,
        },
        {
          hue: '#e7ecf0',
        },
      ],
    },
    {
      featureType: 'road',
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: -60,
        },
      ],
    },
  ],
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  libraries: ['places', 'visualization'],
})(connect(mapStateToProps)(TrackingDashboard));
